import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import TopicsCard from '../../UIComponents/TopicsCard';
import Reorder from 'react-reorder';

const TopicsAndQuizes = props => {

    // eslint-disable-next-line
    const [topics, setTopics] = useState([]);

    useEffect(() => {
        setTopics(props.chapterSelected.topics);
    }, [props.chapterSelected])

    return (
        <Grid container spacing={2} style={{padding: 8}}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant='body1' style={{fontWeight: 700}}>{props.chapterSelected.name}</Typography>
                <Typography variant='body1'>{props.chapterSelected.description}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id='reorder-topics'>
            <Reorder
                reorderId="my-topics"
                reorderGroup="reorder-topics"
                touchHoldTime={200}
                holdTime={100}
                onReorder={props.onReorderTopics}
                disabled={props.nonDeletedTopics.length > 0 ? false : true}
                placeholder={
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4} style={{border: '1px dashed #aaa', background: '#cfd8dc', borderRadius: 4, padding: 4, height: 300}} />
                }
                style={{display: 'flex', flexWrap: 'wrap', boxSizing: 'border-box', padding: 4}}
            >
            {
                props.nonDeletedTopics.map(topic => {
                    return topic.deleted !== true
                        ?   (
                                <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={topic.id} id='my-topics' style={{padding: 4}}>
                                    <TopicsCard 
                                        topic={topic} 
                                        handleDeleteTopic={props.handleDeleteTopic}
                                        handleEditTopic={props.handleEditTopic}
                                        handleStartLiveClass={props.handleStartLiveClass}
                                    />
                                </Grid>
                            )
                        :   null
                })
            }
            </Reorder>
            </Grid>
        </Grid>
    );
}

export default TopicsAndQuizes;
