import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    courses: null,
    selectedCourse: null,
    selectedChapter: null,
    notificationHistory: null,
    restrictedCourses: null
}

const getAllCourses = (state, action) => {
    return updateObject(state, {
        courses: action.courses
    })
}

const newCourseSelected = (state, action) => {
    return updateObject(state, {
        selectedCourse: action.course
    })
}

const setNotificationHistory = (state, action) => {
    return updateObject(state, {
        notificationHistory: action.notifications
    })
}

const setRestrictedCourses = (state, action) => {
    return updateObject(state, {
        restrictedCourses: action.courses
    })
}

const removeCourses = (state, action) => {
    return updateObject(state, {
        courses: null,
        selectedCourse: null,
        selectedChapter: null,
        notificationHistory: null,
        restrictedCourses: null
    });
}

const coursesReducer = (state = initialState , action) => {
    switch(action.type){
        case actionTypes.GET_ALL_COURSES: return getAllCourses(state, action);
        case actionTypes.NEW_COURSE_SELECTED: return newCourseSelected(state, action);
        case actionTypes.NOTIFICATION_HISTORY: return setNotificationHistory(state, action);
        case actionTypes.GET_RESTRICTED_COURSES: return setRestrictedCourses(state, action);
        case actionTypes.REMOVE_COURSES: return removeCourses(state, action);
        default: return state;
    }
}

export default coursesReducer;
