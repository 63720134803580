import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Typography, Badge, IconButton, Dialog, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AiOutlinePlaySquare } from 'react-icons/ai';
import { MdPersonOutline } from 'react-icons/md';
import { FiLogOut } from 'react-icons/fi';
import { BsUpload } from 'react-icons/bs';
import Button from '../../UIComponents/Button';
import ProfileDialog from './ProfileDialog';
import firebase from '../../firebase';
import * as actionTypes from '../../Store/actions/actionTypes';

import * as actionCreators from '../../Store/actions/actionCreators';

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background,
        height: "90vh"
    },
    avatarContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '16px 0px'
    },
    avatar: {
        width: 100,
        height: 100,
        color: '#fff',
    },
    profileTextContainer: {
        width: '100%',
        textAlign: 'center'
    },
    profileText: {
        color: '#fff',
        fontWeight: 700
    },
    iconBtn: {
        background: 'rgba(7, 175, 212, 0.8)',
        padding: '8px',
        '&:hover': {
            background: 'rgb(7, 175, 212)'
        }
    },
    progressDialog: {
        background: 'transparent',
        border: '0px',
        boxShadow: 'none',
        overflow: 'hidden'
    },
    input: {
        display: 'none'
    }
}))

const NavDriver = props => {
    const classes = useStyles()
    const dispatch = useDispatch();

    const authState = useSelector(state => state.auth);

    const [profileDialogOpen, setProfileDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCourseBtnClicked = () => {
        const portal = authState.user.adminData.portal;
        dispatch(actionCreators.updateSelectedCourseContent(null, portal));
    }

    const handleShowProfile = () => {
        setProfileDialogOpen(true)
    }

    const handleLogout = async (user) => {
        await firebase.auth().signOut();
        dispatch({
            type: actionTypes.LOGOUT,
            user: user
        });
        // .then(() => {
        // window.location = '/';
        // });
    }

    const handleProfileEdit = payload => {
        setLoading(true)
        let newUserData = { ...authState.user };
        const newAdminData = { ...authState.user.adminData }
        newAdminData.name = payload.name;
        newAdminData.company = payload.company;
        const adminDataRef = firebase.database().ref('tutors/' + authState.user.phone);
        adminDataRef.set(newAdminData)
        newUserData.adminData = newAdminData;
        dispatch({
            type: actionTypes.LOGIN,
            user: newUserData
        })
        setLoading(false);
    }

    const handleProfileImageUpload = file => {
        setLoading(true)
        const portal = authState.user.adminData.portal;
        const uploadTask = firebase.storage().ref(`${portal}/users/${authState.user.uid}/profile_picture.${file.name.split('.')[1]}`).put(file);
        uploadTask.on('state_changed',
            (snapshot) => {
                //progress snapshot
            },
            (error) => {
                setLoading(false)
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error: true,
                    errorMessage: 'Something went wrong. Please try again.'
                })
            },
            () => {
                firebase.storage().ref(`${portal}/users/${authState.user.uid}`).child(`profile_picture.${file.name.split('.')[1]}`).getDownloadURL().then(url => {
                    let newUserData = { ...authState.user };
                    newUserData.photoURL = url
                    firebase.auth().currentUser.updateProfile({
                        photoURL: url
                    }).then( res => {
                        dispatch({
                            type: actionTypes.LOGIN,
                            user: newUserData
                        })
                        setLoading(false);
                    }
                    ).catch(err => console.log(err))
                }).catch(err => {
                    setLoading(false);
                    dispatch({
                        type: actionTypes.SET_ERROR,
                        error: true,
                        errorMessage: 'Something went wrong. Please try again.'
                    })
                })
            }
        )
    }

    // const getProfilePicture = (profile_picture) => {
    //     const portal = authState.user.adminData.portal;
    //     let profileURL = 'User';
    //     firebase.storage().refFromURL(`gs://tutornex-stage.appspot.com/${portal}/users/${authState.user.phone}/${profile_picture}`).getDownloadURL()
    //         .then(url => {
    //             profileURL = url
    //             return profileURL;
    //         })
    //         .catch(err => {
    //             return 'User'
    //         })
    // }

    return (
        <Fragment>
            <ProfileDialog
                open={profileDialogOpen}
                handleCloseDialog={() => setProfileDialogOpen(false)}
                handleProfileImageUpload={handleProfileImageUpload}
                handleProfileEdit={handleProfileEdit}
            />
            <Dialog open={loading} PaperComponent={classes.progressDialog}>
                <div style={{ padding: 16, overflow: 'hidden' }}>
                    <CircularProgress />
                </div>
            </Dialog>
            <div className={classes.root}>
                <div className={classes.avatarContainer}>
                    <Badge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        badgeContent={
                            <Fragment>
                                <input
                                    accept="image/*"
                                    className={classes.input}
                                    onChange={(e) => handleProfileImageUpload(e.target.files[0])}
                                    id="icon-button-profile-picture"
                                    type="file"
                                />
                                <label htmlFor="icon-button-profile-picture">
                                    <IconButton className={classes.iconBtn} aria-label="upload picture" component="span">
                                        <BsUpload size='18px' color='#fff' />
                                    </IconButton>
                                </label>
                            </Fragment>
                        }
                    >
                        {
                            authState.user !== null && authState.user.photoURL
                                ?   <Avatar 
                                        className={classes.avatar} 
                                        alt={authState.user !== null && authState.user.adminData.name ? authState.user.adminData.name : 'User'}
                                        src={authState.user !== null && authState.user.photoURL ? authState.user.photoURL : 'User'}
                                    />
                                :   <Avatar
                                        className={classes.avatar}
                                    >
                                        <MdPersonOutline size='70px' color='#fff' />
                                    </Avatar> 
                        }
                    </Badge>
                </div>
                <div className={classes.profileTextContainer}>
                    <Typography variant='body1' className={classes.profileText}>Welcome</Typography>
                    <Typography variant='body1' className={classes.profileText}>{authState.user && authState.user.adminData && authState.user.adminData.name ? authState.user.adminData.name : 'User'}</Typography>
                </div>
                <div style={{ padding: 16, textAlign: 'center' }}>
                    <Button btntype='selectedButton' onClick={handleCourseBtnClicked} startIcon={<AiOutlinePlaySquare />} fullWidth disableElevation>Courses</Button>
                </div>
                <div style={{ padding: 16, textAlign: 'center' }}>
                    <Button btntype='textButton' onClick={handleShowProfile} startIcon={<MdPersonOutline />} fullWidth disableElevation>Profile</Button>
                </div>
                <div style={{ padding: 16, textAlign: 'center' }}>
                    <Button btntype='textButton' onClick={() => handleLogout(authState.user)} startIcon={<FiLogOut />} fullWidth disableElevation>Log Out</Button>
                </div>
            </div>
        </Fragment>
    );
}

export default NavDriver;
