import firebase from '../firebase';

export const getIdToken = (call_back) => {

    // Optionally:
    // firebase.auth().onAuthStateChanged((user) => {
    //    if (user) {

    if(firebase.auth().currentUser) {
        firebase.auth().currentUser.getIdToken(true).then((idToken) => {
            call_back({success: true, idToken: idToken});
        }).catch(() => {
            call_back({success: false, errorMessage: 'Unable to get id token for user.'});
        });
    } else {
        call_back({success: false, errorMessage: 'User not logged in.'});
    }
}