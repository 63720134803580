import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import truncate from "truncate-html";

import { RiDeleteBinLine } from 'react-icons/ri';
import { FaEdit } from 'react-icons/fa';
import firebase from '../firebase';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    width: '100%',
    position: 'relative',
  },
  cardActionArea: {

  },
  cardContent: {
    position: 'absolute',
    padding: 8
  },
  cardActions: {
      display: 'flex',
      justifyContent: 'flex-end'
  }
});

export default function CourseCard(props) {
  const classes = useStyles();

  const authState = useSelector(state => state.auth);

  const notchStyle = {
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderLeft: '10px solid #07AFD4',
  }

  const [url, setUrl] = useState('');

  if(authState.loggedIn){
    firebase.storage().ref(authState.user.adminData.portal+'/images/'+props.chapter.image).getDownloadURL().then(function (url) {
      setUrl(url);
    });
  }

return (
    <div style={props.chapter.id === props.chapterSelected.id ? {display: 'flex', alignItems: 'center', width: '100%'} : {display: 'block', paddingRight: 10, width: '100%'}}>
        <Card className={classes.root} style={props.chapter.id === props.chapterSelected.id ? {border: '3px solid #07AFD4'} : {border: '0px'}}>
            <CardActionArea className={classes.cardActionArea} onClick={() => props.handleChapterSelect(props.chapter)}>
                <CardMedia
                    component="img"
                    alt={props.chapter.name}
                    height="140"
                    image={url}
                    title={props.chapter.name}
                />
                <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="body2">
                        {truncate(props.chapter.name, 15)}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions className={classes.cardActions}>
                <IconButton size="small" color="primary" onClick={() => props.handleChapterEdit(props.chapter)}>
                    <FaEdit  color='#07AFD4'/>
                </IconButton>
                <IconButton size="small" color="primary" onClick={() => props.handleChapterDelete(props.chapter)}>
                    <RiDeleteBinLine  color='#F25136'/>
                </IconButton>
            </CardActions>
        </Card>
        {
            props.chapter.id === props.chapterSelected.id ? <div style={notchStyle} /> : null
        }
    </div>
);
}
