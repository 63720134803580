import * as actionTypes from '../../actionTypes';
import firebase from '../../../../firebase';

export const getAllCourses = (portal) => (
    dispatch => {
        const snapshotToArray = courses => {
            let returnArr = [];

            if(courses){
                for(let course in courses){
                    courses[course].deleted !== true && returnArr.push({...courses[course], firebaseRefId: course})
                }
            }
            return returnArr;
        }

        const coursesRef = firebase.database().ref(portal + '/data/courses');
        coursesRef.on('value', snapshot => {
            const courses = snapshotToArray(snapshot.val());
            dispatch({
                type: actionTypes.GET_ALL_COURSES,
                courses: courses
            })
        })

        /*const omitNulls = (data) => {

            const returnArray = [];

            for(let dt in data){
                data[dt] && data[dt] !== null && returnArray.push(data[dt]);
            }

            return returnArray;
        }

        const paidCourseRef = firebase.database().ref(portal + '/data/paid_courses/courses');
        paidCourseRef.on('value', snapshot => {
            const restrictedCourses = snapshot.val() ? omitNulls(snapshot.val()) : [];
            dispatch({
                type: actionTypes.GET_RESTRICTED_COURSES,
                courses: restrictedCourses
            })
        })*/

        const notificationRef = firebase.database().ref(portal + '/data/notifications');
        notificationRef.on('value', snapshot => {
            dispatch({
                type: actionTypes.NOTIFICATION_HISTORY,
                notifications: snapshot.val()
            })
        })
    }
)

export const updateCourse = (courses) => (
    dispatch => {
        const coursesRef = firebase.database().ref('courses');
        coursesRef.update(courses);
    }
)

export const updateSelectedCourseContent = (course, portal) => (
    dispatch => {
        if(course){
            if(course.restricted === true){
                const courseId = course.id;
                const paidCoursesRef = firebase.database().ref(`${portal}/paid_courses/courses/${courseId}`);
                paidCoursesRef.once('value')
                    .then(snapshot => {
                        const newSelectedCourse = snapshot.val();
                        dispatch({
                            type: actionTypes.NEW_COURSE_SELECTED,
                            course: newSelectedCourse
                        });
                    })
            } else {
                dispatch({
                    type: actionTypes.NEW_COURSE_SELECTED,
                    course: course
                });
            }
        } else {
            dispatch({
                type: actionTypes.NEW_COURSE_SELECTED,
                course: null
            });
        }
    }
)
