import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    error: false,
    errorMessage: '',
    success: false,
    successMessage: ''
}

const setError = (state, action) => {
    return updateObject(state, {
        error: action.error,
        errorMessage: action.errorMessage
    })
}

const setSuccess = (state, action) => {
    return updateObject(state, {
        success: action.success,
        successMessage: action.successMessage
    })
}

const errorHandler = (state = initialState , action) => {
    switch(action.type){
        case actionTypes.SET_ERROR: return setError(state, action);
        case actionTypes.SET_SUCCESS: return setSuccess(state, action);
        default: return state;
    }
}

export default errorHandler;