import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import Button from '../../UIComponents/Button';
import * as Constants from '../../helpers/constants';
import firebase from '../../firebase';

const useStyles = makeStyles(theme => ({
    title: {
        background: theme.palette.background,
        padding: 16,
        textAlign: 'center',
        fontWeight: 700,
        color: '#fff',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    input: {
        display: 'none',
    },
    dialogRoot: {
        borderRadius: 20,
    },
    btnContainer: {
        display: 'flex',
        background: '#fff',
        width: '100%',
        paddingBottom: 16,
        justifyContent: 'space-around',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20
    },
}))

export default function LiveClassDialog(props) {
    const classes = useStyles();


    const jitsiContainerStyle = {
        display: 'block',
        width: '100%',
        height: 700,
    }

    let api = null;
    const authState = useSelector(state => state.auth);
    const portal = authState.user.adminData.portal;
    const classCode = props.topic != null ? props.topic.join_link : '';

    const jitsiJoin = (roomCode) => {
        const name = firebase.auth().currentUser.displayName;
        try {
            const domain = Constants.JITSI_URL;
            const options = {
                roomName: roomCode,
                height: 700,
                parentNode: document.getElementById('jitsi-container'),
                interfaceConfigOverwrite: {
                    filmStripOnly: false,
                    SHOW_JITSI_WATERMARK: false,
                },
                configOverwrite: {
                    disableSimulcast: false,
                },
            };
            
            api = new window.JitsiMeetExternalAPI(domain, options);
            api.addEventListener('videoConferenceJoined', () => {
                api.executeCommand('displayName', name);
            });
        } catch (error) {
            console.error('Failed to load Jitsi API', error);
        }
    }

    const makeid = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

    const handleStartClass = () => {
        var roomcode = portal + "-" + makeid(10);
        firebase.database().ref(portal+"/liveclasses/"+classCode).set({
            room: roomcode
        }, function(error) {
            if (error) {
                alert("Error starting the class");
            } else {
                jitsiJoin(roomcode);
            }
        });
    }

    const handleResumeClass = () => {
        firebase.database().ref(portal+"/liveclasses/"+classCode).once('value').then(function(snapshot) {
            var roomcode = (snapshot.val() && snapshot.val().room) || '';
            if(roomcode) {
                jitsiJoin(roomcode);
            } else {
                alert('Class not found');
            }
        });
    }

    const handleStopClass = () => {
        firebase.database().ref(portal+"/liveclasses/"+classCode).set(null, function(error) {
            if (error) {
                alert("Error ending the class");
            } else {
                alert("Class Closed");
            }
        });
        document.getElementById('jitsi-container').innerHTML = '';
    }

    return (
        <Dialog fullScreen open={props.open} onClose={props.handleDialogClose}>
            <div style={{background: '#fff', borderRadius: 20}}>
                    <Typography variant='body1' className={classes.title}>Live Class: {props.topic != null ? props.topic.name : ''}</Typography>
                    <DialogActions>
                    <div className={classes.btnContainer}>
                        <Button btntype='outlinedPrimaryButton' onClick={props.handleDialogClose} fullWidth style={{margin: '0px 22px'}}>Cancel</Button>
                        <Button btntype='outlinedPrimaryButton' onClick={handleStartClass} fullWidth style={{margin: '0px 22px'}}>Start</Button>
                        <Button btntype='outlinedPrimaryButton' onClick={handleResumeClass} fullWidth style={{margin: '0px 22px'}}>Resume</Button>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={handleStopClass}
                            className={classes.button}
                            startIcon={<DeleteIcon />}
                        >
                            End Class
                        </Button>
                    </div>
                    </DialogActions>

                    <DialogContent style={{padding: '32px'}}>
                    <div
                        id="jitsi-container"
                        style={jitsiContainerStyle}
                    />
                    </DialogContent>
                </div>
        </Dialog>
    );
}
