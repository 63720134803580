import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import * as actionTypes from '../../Store/actions/actionTypes';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GeneralizedSnackBar = props => {
    const dispatch = useDispatch();

    const errorHandlerState = useSelector(state => state.errorHandler);

    const [openSnackbar, setOpenSnackBar] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('error')

    useEffect(() => {
        if(errorHandlerState.error){
            setSeverity('error')
            setMessage(errorHandlerState.errorMessage);
            setOpenSnackBar(true);
        } else if(errorHandlerState.success === true){
            setSeverity('success')
            setMessage(errorHandlerState.successMessage);
            setOpenSnackBar(true);
        }
    }, [errorHandlerState])

    const handleClose = () => {
        severity === 'error'
            ?   dispatch({
                    type: actionTypes.SET_ERROR,
                    error: false,
                    errorMessage: ''
                })
            :   dispatch({
                    type: actionTypes.SET_SUCCESS,
                    success: false,
                    successMessage: ''
                })
        
        setOpenSnackBar(false);
    }

    return (
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert onClose={handleClose} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default GeneralizedSnackBar;
