import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Grid, Typography, Paper, Avatar, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios'
import firebase from '../../firebase';
import { RiDeleteBinLine } from 'react-icons/ri';
import { MdPhone, MdAccessTime } from 'react-icons/md'
import * as actionTypes from '../../Store/actions/actionTypes';

import Button from '../../UIComponents/Button';
import DeleteDialog from './DeleteCourseDialog';
import { getIdToken } from '../../helpers/getIdToken';
import * as Constants from '../../helpers/constants';

const useStyles = makeStyles(theme => ({
    root: {
        height: '68.2vh',
        overflowX: 'auto'
    },
    loader: {
        width: '100%',
        height: '50vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        display: 'flex', 
        alignItems: 'center',
        height: 200, 
        borderRadius: 8,
        background: 'rgba(7, 175, 212, 0.5)',
    },
    paperLeftChild: {
        flexGrow: 1, 
        borderTopLeftRadius: 8, 
        borderBottomLeftRadius: 8
    },
    paperRightChild: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        width: 80, 
        height: 200, 
        background: '#f25f5c',
        // background: '#F25136', 
        borderBottomRightRadius: 8, 
        borderTopRightRadius: 8,
        // opacity: 0.9
    }
}))

const ManageCourseStudents = props => {
    const classes = useStyles();
    const baseURL = Constants.BASE_URL;
    const dispatch = useDispatch();
    
    const courseState = useSelector(state => state.courses)
    const authState = useSelector(state => state.auth)

    const [loading, setLoading] = useState(true);

    const [students, setStudents] = useState(null);
    const [error, setError] = useState([false, '']);

    const [del, setDel] = useState({open: false, student: null});

    const fetchData = () => {
        error[0] && setError([false, '']);
        !loading && setLoading(true);

        getIdToken((res) => {
            if(res.success) {
                const idToken = res.idToken;
                const courseId = courseState.selectedCourse.id;
                const url = `${baseURL}getStudents?courseId=${courseId}`;
                axios.get(url, { headers: {authorization: idToken} })
                    .then(res => {
                        if(res.data && res.data.status && res.data.status === 'success'){
                            setStudents(res.data.students);
                        } else {
                            setError([true, 'Unable to fetch students for the course.']);
                        }
                        setLoading(false)
                    })
                    .catch(err => {
                        setError([true, 'Unable to fetch students for the course.'])
                        setLoading(false);
                    })
            } else {
                setError([true, 'Unable to fetch user Id Token.'])
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        fetchData();  
        // eslint-disable-next-line      
    }, [])

    useEffect(() => {
        if(props.fetchStudents){
            fetchData();
            props.setFetchStudents(false);
        }
        // eslint-disable-next-line
    }, [props.fetchStudents])

    const handleDelete = (student) => {
        props.setProgress({open: true, value: 0});
        getIdToken((res) => {
            if(res.success){
                const idToken = res.idToken;
                const phoneNumber = student.phone[0] === '+' ? student.phone.slice(1) : student.phone;
                const baseURL = Constants.BASE_URL;
                const url = `${baseURL}getUID?phone=${phoneNumber}`;

                axios.get(url, { headers: {authorization: idToken} })
                    .then(res => {
                        setDel({open: false, student: null});
                        if(res.data && res.data.status && res.data.status === 'success'){
                            const uid = res.data.uid;
                            const courseId = courseState.selectedCourse.id;
                            const portal = authState.user.adminData.portal;
                            const db = `${portal}/paid_accesses/${uid}/${courseId}`;
                            const paidAccessesRef = firebase.database().ref(db);
                            
                            paidAccessesRef.set(null).then(() => {
                                props.setProgress({open: false, value: 0});
                                fetchData();
                                dispatch({
                                    type: actionTypes.SET_SUCCESS,
                                    success: true,
                                    successMessage: 'Access to this course deleted successfully.'
                                })
                            }).catch(err => {
                                props.setProgress({open: false, value: 0});
                                dispatch({
                                    type: actionTypes.SET_ERROR,
                                    error: true,
                                    errorMessage: 'Student has no access to this course. Please refresh page.'
                                })
                            });
                        } else {
                            props.setProgress({open: false, value: 0});
                            dispatch({
                                type: actionTypes.SET_ERROR,
                                error: true,
                                errorMessage: 'Student is not on the platform yet.'
                            })
                        }
                    })
                    .catch(err => {
                        props.setProgress({open: false, value: 0});
                        dispatch({
                            type: actionTypes.SET_ERROR,
                            error: true,
                            errorMessage: 'Something went wrong while getting student uid.'
                        })
                    })
            } else {
                props.setProgress({open: false, value: 0});
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error: true,
                    errorMessage: res.errorMessage
                })
            }
        })
    }

    const getStudentCardJSX = (student, i) => {
        return (
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3} style={{position: 'relative'}} key={i}>
                <Paper className={classes.paper} elevation={8}>
                    <div className={classes.paperLeftChild}>
                        <div style={{display: 'flex', alignItems: 'center', padding: 8}}>
                            <Avatar style={{background: '#EAAE21'}}>
                                <span style={{fontWeight: 700, color: '#fff'}}>
                                    {student.name ? student.name[0] : ""}
                                </span>    
                            </Avatar>
                            <Typography style={{marginLeft: 8, color: '#fff', fontWeight: 700}}>
                                    {student.name ? student.name : "Unknown"}
                            </Typography>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', padding: 8}}>
                            <Avatar style={{background: '#EAAE21'}}>
                                <MdPhone color='#fff' />    
                            </Avatar>
                            <Typography style={{marginLeft: 8, color: '#fff', fontWeight: 700}}>
                                    {student.phone}
                            </Typography>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', padding: 8}}>
                            <Avatar style={{background: '#EAAE21'}}>
                                <MdAccessTime color='#fff' />    
                            </Avatar>
                            <Typography style={{marginLeft: 8, color: '#fff', fontWeight: 700}}>
                                    {student.access_until}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.paperRightChild}>
                        <IconButton onClick={() => setDel({open: true, student: student})}>
                            <RiDeleteBinLine color='#fff' size='25px' />
                        </IconButton>
                    </div>
                </Paper>
            </Grid>
        )
    }

    return (
        <Grid container className={classes.root}>
            <DeleteDialog 
                open={del.open}
                deleteCourse={del.student}
                handleCloseDialog={() => setDel({open: false, student: null})}
                handleCourseDelete={handleDelete}
            />
            {
                loading 
                    ?   <div className={classes.loader}><CircularProgress /></div> 
                    :   <>
                            {
                                error[0]
                                    ?   <div className={classes.loader}>
                                            <div>
                                                <Typography>{error[1]}</Typography>
                                                <div style={{height: 16}} />
                                                <Button onClick={() => fetchData()}>Try Again</Button>
                                            </div>
                                        </div>
                                    :   <Grid container spacing={2}>
                                            {
                                                (students && students.length > 0)
                                                    ?   students.map((student, i) => getStudentCardJSX(student, i))
                                                    :   <div className={classes.loader}>
                                                            <Typography>There are no students added yet to this course.</Typography>
                                                        </div>
                                            }
                                        </Grid>
                            }
                        </>   
            }
        </Grid>
    )
}

export default ManageCourseStudents;
