import React from 'react';
import PhoneInput from 'react-phone-input-2';
import './phone-input.css';

const MyPhoneInput = props => {
    return (
        <PhoneInput 
            country={props.country}
            isValid={props.isValid}
            enableSearch={props.enableSearch}
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            disabled={props.disabled}
            helperText={props.helperText}
        />
    )
}

export default MyPhoneInput;