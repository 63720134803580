import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Typography, Avatar, Badge, IconButton, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { BsUpload } from 'react-icons/bs';
import { MdPersonOutline } from 'react-icons/md';
import Button from '../../UIComponents/Button';
import * as actionTypes from '../../Store/actions/actionTypes';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 350,
        background: '#fff',
        borderRadius: 20,
    },
    topBar: {
        background: theme.palette.background,
        width: '100%',
        textAlign: 'center',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    topBarText: {
        color: '#fff',
        fontWeight: 700,
        padding: 8
    },
    avatarContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '16px 0px'
    },
    avatar: {
        width: 100,
        height: 100,
        color: '#fff'
    },
    profileTextContainer: {
        textAlign: 'center',
        padding: '8px 0px',
        maxWidth: 350,
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20
    },
    profileText: {
        fontWeight: 700
    },
    iconBtn: {
        background: 'rgba(7, 175, 212, 0.8)',
        padding: '8px',
        '&:hover': {
            background: 'rgb(7, 175, 212)'
        }
    },
    input: {
        display: 'none'
    },
    dialogRoot: {
        borderRadius: 20,
    },
}))

const ProfileDialog = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth);

    const [editProfile, setEditProfile] = useState(false);
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');

    useEffect(() => {
        if (editProfile === true) {
            setName(authState.user !== null && authState.user.adminData.name ? authState.user.adminData.name : 'User');
            setCompany(authState.user !== null && authState.user.adminData.company ? authState.user.adminData.company : '');
        }
        // eslint-disable-next-line
    }, [editProfile])

    const handleSaveChanges = () => {
        let error = false;
        let errorMessage = '';
        if (name === '') {
            error = true;
            errorMessage = 'Name field cannot be empty.'
        }
        if (company === '') {
            error = true;
            errorMessage = 'Company field cannot be empty.'
        }
        if (name === '' && company === '') {
            error = true;
            errorMessage = 'Name and Company field cannot be empty.'
        }
        if (error === true) {
            dispatch({
                type: actionTypes.SET_ERROR,
                error: true,
                errorMessage: errorMessage
            })
        } else {
            props.handleProfileEdit({ name: name, company: company });
            setEditProfile(false);
        }
    }

    const nonEditableJSX = (
        <Fragment>
            <Typography variant='body1' className={classes.profileText}>Name : {authState.user !== null && authState.user.adminData.name ? authState.user.adminData.name : 'User'}</Typography>
            <Typography variant='body1' className={classes.profileText}>Company Name : {authState.user !== null && authState.user.adminData.company ? authState.user.adminData.company : 'Not Available'}</Typography>
            <Typography variant='body1' className={classes.profileText}>Phone Number : {authState.user !== null && authState.user.phone}</Typography>
            <Button onClick={() => setEditProfile(true)} style={{ margin: '20px 0px' }}>Edit Profile</Button>
        </Fragment>
    )

    const editJSX = (
        <Fragment>
            <TextField
                autoFocus
                value={name}
                margin="dense"
                variant='outlined'
                onChange={(e) => setName(e.target.value)}
                id="name"
                label="Name"
                fullWidth
                style={{ maxWidth: 300 }}
            />
            <TextField
                autoFocus
                value={company}
                margin="dense"
                variant='outlined'
                onChange={(e) => setCompany(e.target.value)}
                id="name"
                label="Company"
                fullWidth
                style={{ maxWidth: 300 }}
            />
            <Button btntype='outlinedPrimaryButton' onClick={() => setEditProfile(false)} style={{ margin: '20px 8px' }}>Cancel</Button>
            <Button onClick={handleSaveChanges} style={{ margin: '20px 8px' }}>Save Changes</Button>
        </Fragment>
    )

    return (
        <Dialog onClose={props.handleCloseDialog} open={props.open} PaperComponent={classes.dialogRoot}>
            <div className={classes.root}>
                <div className={classes.topBar}>
                    <Typography variant='body1' className={classes.topBarText}>Profile</Typography>
                </div>
                <div className={classes.avatarContainer}>
                    <Badge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        badgeContent={
                            <Fragment>
                                <input
                                    accept="image/*"
                                    className={classes.input}
                                    onChange={(e) => props.handleProfileImageUpload(e.target.files[0])}
                                    id="icon-button-profile-picture"
                                    type="file"
                                />
                                <label htmlFor="icon-button-profile-picture">
                                    <IconButton className={classes.iconBtn} aria-label="upload picture" component="span">
                                        <BsUpload size='18px' color='#fff' />
                                    </IconButton>
                                </label>
                            </Fragment>
                        }
                    >
                        {
                            authState.user !== null && authState.user.photoURL
                                ?   <Avatar 
                                        className={classes.avatar} 
                                        alt={authState.user !== null && authState.user.adminData.name ? authState.user.adminData.name : 'User'}
                                        src={authState.user !== null && authState.user.photoURL ? authState.user.photoURL : 'User'}
                                    />
                                :   <Avatar
                                        className={classes.avatar}
                                        alt={<MdPersonOutline />}
                                        src={<MdPersonOutline />}
                                    >
                                        <MdPersonOutline size='70px' color='#fff' />
                                    </Avatar> 
                        }
                    </Badge>
                </div>
                <div className={classes.profileTextContainer}>
                    {
                        editProfile === true ? editJSX : nonEditableJSX
                    }
                </div>
            </div>
        </Dialog>
    );
}

export default ProfileDialog;
