import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import firebase from '../../firebase';
import { Grid, CircularProgress, Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MdAdd } from 'react-icons/md';
import Button from '../../UIComponents/Button';
import CourseTabs from './CourseTabs';
import SelectedCourseBody from './SelectedCourseBody';
import AddOrEditChapterDialog from '../../Components/AddOrEditChapterDialog/AddOrEditChapterDialog';
import AddOrEditTopic from '../../Components/AddOrEditTopic/AddOrEditTopic';
import AddOrEditQuiz from '../../Components/AddOrEditQuiz/AddOrEditQuiz';
import DeleteDialog from './DeleteCourseDialog';
import AddStudentToRestrictedCourse from './AddStudentToRestrictedCourse';
import LiveClassDialog from '../../Components/LiveClassDialog/LiveClassDialog';
import * as actionTypes from '../../Store/actions/actionTypes';
import * as actionCreators from '../../Store/actions/actionCreators';

import ManageCourseStudents from './ManageCourseStudents';

const useStyles = makeStyles(theme => ({
    topBarBtnContainer:{
        display: 'flex',
        justifyContent: 'flex-end'
    },
    loader: {
        padding: 16,
        textAlign: 'center',
        width: '100%'
    },
    progressDialog: {
        background: 'transparent',
        border: '0px',
        boxShadow: 'none'
    },
    tabContainer: {
        padding: 4,
        borderRadius: 8,
        background: 'rgba(7, 175, 212, 0.2)',
        display: 'flex'
    },
    selectedTab: {
        color: '#fff',
        margin: 4,
        padding: 8,
        borderRadius: 8,
        background: '#EAAE21',
        fontWeight: 600,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    unselectedTab: {
        color: '#fff',
        margin: 4,
        padding: 8,
        borderRadius: 8,
        background: 'rgba(7, 175, 212, 0.5)',
        '&:hover': {
            cursor: 'pointer',
        },
    }
}))

const CourseSelected = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const coursesState = useSelector(state => state.courses);
    const authState = useSelector(state => state.auth);

    const [addOrEditChapter, setAddOrEditChapter] = useState({open: false, chapter: null})
    const [addOrEditTopic, setAddOrEditTopic] = useState({open: false, topic: null, type: null})
    const [addOrEditQuiz, setAddOrEditQuiz] = useState({open: false, topic: null, type: null})
    const [deleteChapter, setDeleteChapter] = useState({open: false, chapter: null})
    const [deleteTopic, setDeleteTopic] = useState({open: false, topic: null})
    const [selectedChapter, setChapterSelected] = useState(null);
    const [progress, setProgress] = useState({open: false, value: 0});
    const [addStudent, setAddStudent] = useState(false);
    const [fetchStudents, setFetchStudents] = useState(false);
    const [liveClass, setLiveClass] = useState({open: false, topic: null})

    const [selectedBody, setSelectedBody] = useState('course_content')

    useEffect(() => {
        if(coursesState){
            if(coursesState.selectedCourse){
                if(coursesState.selectedCourse.chapters){
                    if(selectedChapter === null || selectedChapter === undefined){
                        setChapterSelected(coursesState.selectedCourse.chapters[0]);
                    }
                    if(coursesState.selectedChapter !== null && coursesState.selectedChapter !== undefined){
                        const updatedChapters = coursesState.courses.filter(course => course.firebaseRefId === coursesState.selectedCourse.firebaseRefId)[0].chapters;
                        if(coursesState.selectedCourse.chapters.length !== updatedChapters.length){
                            const portal = authState.user.adminData.portal;
                            const c = coursesState.courses.filter(course => course.firebaseRefId === coursesState.selectedCourse.firebaseRefId)[0];
                            dispatch(actionCreators.updateSelectedCourseContent(c, portal));
                        }
                    }
                } else {
                    if(selectedChapter === null || selectedChapter === undefined){
                        setChapterSelected([]);
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [coursesState])

    useEffect(() => {
        if(selectedChapter === null || selectedChapter === undefined){
            if(coursesState.selectedCourse.chapters){
                setChapterSelected(coursesState.selectedCourse.chapters[0])
            }
        }
        // eslint-disable-next-line
    }, [coursesState.selectedCourse])

    const uploadFile = (payload, fileType, file, additionalData, callback) => {
        const portal = authState.user.adminData.portal;
        const fileName = `${payload.name.replace(/[^a-zA-Z0-9]/g, "")}-${Date.now()}`;
        const uploadTask = firebase.storage().ref(`${portal}/${fileType === 'image' ? 'images' : 'notes'}/${fileName}`).put(file);
        uploadTask.on('state_changed',
            (snapshot) => {
                setProgress({open: true, value: snapshot.bytesTransferred/snapshot.totalBytes * 100});
            },
            (error) => {
                console.log(error);
                setProgress({open: false, value: 0});
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error: true,
                    errorMessage: 'Something went wrong. Please try again.'
                })
            },
            () => {
                firebase.storage().ref(`${portal}/${fileType === 'image' ? 'images' : 'notes'}`).child(fileName).getDownloadURL().then(url => {
                    callback(payload, fileName, additionalData);
                    setProgress({open: false, value: 0});
                }).catch( err => {
                    console.log(err);
                    setProgress({open: false, value: 0});
                    dispatch({
                        type: actionTypes.SET_ERROR,
                        error: true,
                        errorMessage: 'Something went wrong. Please try again.'
                    })
                })
            }
        )
    }

    const handleAddNewChapter = (payload) => {

        uploadFile(payload, 'image', payload.image, null, (payload, fileName) => {
            payload.image = fileName;
            const refId = coursesState.selectedCourse.firebaseRefId;
            const portal = authState.user.adminData.portal;
            let newCourse = {};

            const getNewCourse = (completeCourse) => {
                return {
                    ...completeCourse,
                    firebaseRefId: null,
                    chapters: (completeCourse && completeCourse.chapters)
                        ?    [...completeCourse.chapters, {
                                ...payload, 
                                id: completeCourse.chapters.length + 1
                            }]
                        :   [{...payload, id: 1}]
                }
            }

            if(coursesState.selectedCourse.restricted === true){
                const completeCourse = coursesState.selectedCourse;
                const courseId = completeCourse.id;
                const dbPath = `${portal}/paid_courses/courses/${courseId}`;
                newCourse = getNewCourse(completeCourse);
                const restrictedCourseRef = firebase.database().ref(dbPath);
                restrictedCourseRef.set(newCourse);
            } else {
                const completeCourse = {...coursesState.selectedCourse};
                const dbPath = '/data/courses';
                newCourse = getNewCourse(completeCourse);
                const newCourses = [];
                coursesState.courses.forEach(c => {
                    if(c.id === newCourse.id){
                        newCourse.firebaseRefId = null;
                        newCourses.push(newCourse);
                    } else {
                        c.firebaseRefId = null;
                        newCourses.push(c);
                    }
                })
                const coursesRef = firebase.database().ref(portal + dbPath)
                coursesRef.set(newCourses);
            }
            dispatch(actionCreators.getAllCourses(portal));
            setAddOrEditChapter({open: false, courseForEdit: null})
            dispatch(actionCreators.updateSelectedCourseContent({...newCourse, firebaseRefId: refId}, portal));
            setChapterSelected(newCourse.chapters[newCourse.chapters.length - 1]);
        })

    }

    const handleAddnewTopic = (payload, questionId) => {

        const addTopic = payload => {

            let topic = {};
            let newChapter = {...selectedChapter};

            if(payload.type === 'quiz' && addOrEditQuiz.topic !== null && addOrEditQuiz.topic !== undefined){
                if(selectedChapter.topics.filter(topic => topic.id === addOrEditQuiz.topic.id).length === 0){
                    topic = {
                        ...payload,
                        id: selectedChapter.topics ? selectedChapter.topics.length + 1 : 1
                    }
                    if(selectedChapter.topics){
                        newChapter.topics.push(topic);
                    } else {
                        newChapter = {
                            ...newChapter,
                            topics: [{...topic}]
                        }
                    }
                } else {
                    let temp = [];
                    topic = {
                        ...selectedChapter.topics.filter(topic => topic.id === addOrEditQuiz.topic.id)[0],
                        ...payload
                    }
                    newChapter.topics.forEach(t => {
                        temp.push(t.id === addOrEditQuiz.topic.id ? topic : t) 
                    })
                    newChapter = {
                        ...newChapter,
                        topics: temp
                    }
                }
            } else if(addOrEditTopic.topic !== null && addOrEditTopic.topic !== undefined){
                if(selectedChapter.topics.filter(topic => topic.id === addOrEditTopic.topic.id).length === 0){
                    topic = {
                        ...payload,
                        id: selectedChapter.topics ? selectedChapter.topics.length + 1 : 1
                    }
                    if(selectedChapter.topics){
                        newChapter.topics.push(topic);
                    } else {
                        newChapter = {
                            ...newChapter,
                            topics: [{...topic}]
                        }
                    }
                } else {
                    let temp = [];
                    topic = {
                        ...selectedChapter.topics.filter(topic => topic.id === addOrEditTopic.topic.id)[0],
                        ...payload
                    }
                    newChapter.topics.forEach(t => {
                        temp.push(t.id === addOrEditTopic.topic.id ? topic : t) 
                    })
                    newChapter = {
                        ...newChapter,
                        topics: temp
                    }
                }
            } else {
                topic = {
                    ...payload,
                    id: selectedChapter.topics ? selectedChapter.topics.length + 1 : 1
                }
                if(selectedChapter.topics){
                    newChapter.topics.push(topic);
                } else {
                    newChapter = {
                        ...newChapter,
                        topics: [{...topic}]
                    }
                }
            }
            
            const newChapters = [...coursesState.selectedCourse.chapters];
            newChapters.forEach((chapter, i) => {
                newChapters[i] = chapter.id === newChapter.id ? {...newChapter} : {...chapter}
            })
            const refId = coursesState.selectedCourse.firebaseRefId;
            const newCourse = {
                ...coursesState.selectedCourse,
                firebaseRefId: null,
                chapters: [...newChapters]
            }
            
            const newCourses = [];
            coursesState.courses.forEach(c => {
                if(c.id === newCourse.id){
                    newCourse.firebaseRefId = null;
                    newCourses.push(newCourse);
                } else {
                    c.firebaseRefId = null;
                    newCourses.push(c);
                }
            })

            const portal = authState.user.adminData.portal;
            const coursesRef = firebase.database().ref(portal + '/data/courses');
            coursesRef.set(newCourses);

            dispatch(actionCreators.updateSelectedCourseContent({
                ...newCourse,
                firebaseRefId: refId
            }, portal));

            setChapterSelected(newChapter)
            if(payload.type === 'quiz'){
                setAddOrEditQuiz({open: true, topic: topic, type: 'quiz'})
                setProgress({open: false, value: 0});
            }
            setAddOrEditTopic({open: false, topic: null, type: null});
            dispatch(actionCreators.getAllCourses(portal));
        }

        const recursiveImageUpload = (payload, i, length, callback) => {
            const que = payload.questions.filter(q => q.id === questionId)[0];
            if(que.choices[i].image_choice !== null && que.choices[i].image_choice !== undefined && typeof que.choices[i].image_choice !== 'string'){
                uploadFile(payload, 'image', que.choices[i].image_choice, null, (payload, fileName) => {
                    que.choices[i].image_choice = fileName;
                    const newQuestions = [];
                    payload.questions.forEach(q => {
                        if(q.id === questionId){
                            newQuestions.push(que);
                        } else {
                            newQuestions.push(q);
                        }
                    })
                    payload.questions = [...newQuestions];
                    i = i + 1;
                    if(i < length){
                        recursiveImageUpload(payload, i, length, callback);
                    } else {
                        callback(payload);
                    }
                })
            } else {
                i = i + 1;
                if(i < length){
                    recursiveImageUpload(payload, i, length, callback);
                } else {
                    callback(payload);
                }
            }
        }

        if(payload.type === 'quiz'){
            setProgress({open: true, value: 0});
            if(payload.image !== null && payload.image !== undefined && typeof payload.image !== 'string'){
                uploadFile(payload, 'image', payload.image, null, (payload, imageName) => {
                    payload.image = imageName;
                    recursiveImageUpload(payload, 0, 4, (payload) => {
                        addTopic(payload);
                    })
                })
            } else {
                recursiveImageUpload(payload, 0, 4, (payload) => {
                    addTopic(payload);
                })
            }
        } else {
            if(payload.image !== null && payload.image !== undefined && typeof payload.image !== 'string'){
                uploadFile(payload, 'image', payload.image, null, (payload, fileName) => {
                    payload.image = fileName;
                    uploadNotes(payload, addTopic);
                })
            } else {
                uploadNotes(payload, addTopic);
            }
        }
    }

    const uploadNotes = (payload, addTopic) => {
        if(payload.additional_notes !== null && payload.additional_notes !== undefined && typeof payload.additional_notes !== 'string'){
            uploadFile(payload, 'notes', payload.additional_notes, null, (payload, fileName) => {
                payload.additional_notes = fileName;
                addTopic(payload);
            })
        } else {
            addTopic(payload);
        }
    }

    const handleSelectedCourseChange = newValue => {
        setSelectedBody('course_content');
        const portal = authState.user.adminData.portal;
        dispatch(actionCreators.updateSelectedCourseContent(coursesState.courses[newValue], portal));
        if(coursesState.courses[newValue].chapters){
            setChapterSelected(coursesState.courses[newValue].chapters[0])
        } else {
            setChapterSelected(null);
        }
    }

    const handleChapterEdit = (payload, id, newImage) => {
        const getUpdatedChapters = (chapters, payload, id) => {
            const newChapters = [];
            chapters.forEach(chapter => {
                if(chapter.id === id){
                    newChapters.push({
                        ...chapter,
                        ...payload
                    })
                } else {
                    newChapters.push(chapter);
                }
            })
            return newChapters;
        }

        const updateChapter = (payload, id) => {
            const refId = coursesState.selectedCourse.firebaseRefId;
            const newCourse = {
                ...coursesState.selectedCourse,
                firebaseRefId: null,
                chapters: getUpdatedChapters(coursesState.selectedCourse.chapters, payload, id)
            }
            const newCourses = [];
            coursesState.courses.forEach(course => {
                if(course.id === newCourse.id){
                    newCourses.push({...newCourse, firebaseRefId: null});
                } else {
                    newCourses.push({...course, firebaseRefId: null});
                }
            })
            const portal = authState.user.adminData.portal;
            const coursesRef = firebase.database().ref(portal + '/data/courses')
            coursesRef.update(newCourses);

            dispatch(actionCreators.updateSelectedCourseContent({
                ...newCourse,
                firebaseRefId: refId
            }, portal));

            setAddOrEditChapter({open: false, chapter: null})
        }

        if(newImage){
            uploadFile(payload, 'image', payload.image, id, (payload, fileName, id) => {
                payload.image = fileName;
                updateChapter(payload, id)
            })
        } else {
            updateChapter(payload, id)
        }
        
        
    }

    const handleChapterDelete = payload => {
        const getUpdatedChapters = (chapters, payload) => {
            const newChapters = [];
            chapters.forEach(chapter => {
                if(chapter.id === payload.id){
                    newChapters.push({
                        ...chapter,
                        deleted: true
                    })
                } else {
                    newChapters.push(chapter);
                }
            })
            return newChapters;
        }
        const refId = coursesState.selectedCourse.firebaseRefId;
        const newCourse = {
            ...coursesState.selectedCourse,
            firebaseRefId: null,
            chapters: getUpdatedChapters(coursesState.selectedCourse.chapters, payload)
        }
        const portal = authState.user.adminData.portal;
        const coursesRef = firebase.database().ref(portal + '/data/courses').child(coursesState.selectedCourse.firebaseRefId);
        coursesRef.update(newCourse);

        dispatch(actionCreators.updateSelectedCourseContent({
            ...newCourse,
            firebaseRefId: refId
        }, portal));

        setDeleteChapter({open: false, chapter: null})
    }

    const handleTopicDelete = payload => {
        const topic = {...payload};
        let newChapter = {...selectedChapter};
        const newTopics = [];
        newChapter.topics.forEach(t => {
            if(t.id === topic.id){
                newTopics.push({
                    ...t,
                    deleted: true
                })
            } else {
                newTopics.push(t);
            }
        })
        newChapter.topics = [...newTopics];
        const newChapters = [...coursesState.selectedCourse.chapters];
        newChapters.forEach((chapter, i) => {
            newChapters[i] = chapter.id === newChapter.id ? {...newChapter} : {...chapter}
        })
        const newCourse = {
            ...coursesState.selectedCourse,
            firebaseRefId: null,
            chapters: [...newChapters]
        }
        const portal = authState.user.adminData.portal;
        const coursesRef = firebase.database().ref(portal + '/data/courses').child(coursesState.selectedCourse.firebaseRefId);
        coursesRef.update(newCourse);

        dispatch(actionCreators.updateSelectedCourseContent({
            ...newCourse,
            firebaseRefId: null
        }, portal));
        
        setChapterSelected(newChapter)
        setDeleteTopic({open: false, topic: null});
    }

    const handleEditTopic = payload => {
        if(coursesState.selectedCourse.chapters){
            setAddOrEditTopic(payload);
        } else {
            dispatch({
                type: actionTypes.SET_ERROR,
                error: true,
                errorMessage: 'Please add a chapter first.'
            })
        }
    }

    const handleAddNewQuiz = payload => {
        if(coursesState.selectedCourse.chapters){
            setAddOrEditQuiz(payload)
        } else {
            dispatch({
                type: actionTypes.SET_ERROR,
                error: true,
                errorMessage: 'Please add a chapter first.'
            })
        }
    }

    return (
        <Fragment>
            {
                selectedChapter !== null
                    ?   <Fragment>
                            <LiveClassDialog
                                open = {liveClass.open}
                                topic = {liveClass.topic}
                                handleDialogClose={() => setLiveClass({open: false, topic: null})}
                            />
                            <AddOrEditChapterDialog 
                                open={addOrEditChapter.open}
                                chapter={addOrEditChapter.chapter}
                                handleDialogClose={() => setAddOrEditChapter({open: false, chapter: null})}
                                handleAddNewChapter={handleAddNewChapter}
                                handleChapterEdit={handleChapterEdit}
                            />
                            <AddOrEditTopic 
                                open = {addOrEditTopic.open}
                                topic = {addOrEditTopic.topic}
                                type = {addOrEditTopic.type}
                                handleDialogClose={() => setAddOrEditTopic({open: false, topic: null, type: null})}
                                handleAddnewTopic={handleAddnewTopic}
                            />
                            <AddOrEditQuiz 
                                open = {addOrEditQuiz.open}
                                topic = {addOrEditQuiz.topic}
                                type = {addOrEditQuiz.type}
                                handleDialogClose={() => setAddOrEditQuiz({open: false, topic: null, type: null})}
                                topicReorderStateUpdate={(newTopic) => setAddOrEditQuiz({open: true, topic: newTopic, type: 'quiz'})}
                                handleAddnewTopic={handleAddnewTopic}
                                chapterSelected={selectedChapter}
                                handleChapterSelect={(chapter) => setChapterSelected(chapter)}
                            />
                            <DeleteDialog 
                                open={deleteChapter.open}
                                deleteCourse={deleteChapter.chapter}
                                handleCloseDialog={() => setDeleteChapter({open: false, chapter: null})}
                                handleCourseDelete={handleChapterDelete}
                            />
                            <DeleteDialog
                                open={deleteTopic.open}
                                deleteCourse={deleteTopic.topic}
                                handleCloseDialog={() => setDeleteTopic({open: false, chapter: null})}
                                handleCourseDelete={handleTopicDelete}
                            />
                            <Dialog open={progress.open} PaperComponent={classes.progressDialog}>
                                <CircularProgress  value={progress.value} />
                            </Dialog>
                            <AddStudentToRestrictedCourse 
                                open={addStudent}
                                onClose={setAddStudent}
                                setProgress={setProgress}
                                setFetchStudents={setFetchStudents}
                            />
                            <div style={{width: '100%', padding: 16}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <CourseTabs 
                                            courses={coursesState.courses}
                                            handleCourseChange={handleSelectedCourseChange}
                                            selectedCourse={coursesState.selectedCourse}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{display: 'flex'}}>
                                        {
                                            coursesState.selectedCourse && coursesState.selectedCourse.restricted
                                                ?   <div className={classes.tabContainer}>
                                                        <div className={selectedBody === 'course_content' ? classes.selectedTab : classes.unselectedTab} onClick={() => setSelectedBody('course_content')}>
                                                            <Typography>
                                                                Course Content
                                                            </Typography>
                                                        </div>
                                                        <div className={selectedBody === 'course_students' ? classes.selectedTab : classes.unselectedTab} onClick={() => setSelectedBody('course_students')}>
                                                            <Typography>
                                                                Course Students
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                :   null
                                        }
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8} className={classes.topBarBtnContainer}>
                                        {
                                            selectedBody === 'course_content'
                                                ?   <>
                                                        <Button btntype='containedButton' startIcon={<MdAdd />} onClick={() => setAddOrEditChapter({open: true, chapter: null})} style={{margin: '0px 4px'}}>Add Chapter</Button>
                                                        <Button btntype='containedButton' startIcon={<MdAdd />} onClick={() => handleEditTopic({open: true, topic: null, type: 'topic'})} style={{margin: '0px 4px'}}>Add Topic</Button>
                                                        <Button btntype='containedButton' startIcon={<MdAdd />} onClick={() => handleAddNewQuiz({open: true, topic: null, type: 'quiz'})} style={{margin: '0px 4px'}}>Add Quiz</Button>
                                                    </>
                                                :   <Button btntype='containedButton' startIcon={<MdAdd />} style={{margin: '0px 4px'}} onClick={() => setAddStudent(true)}>Add Student</Button>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        {
                                            coursesState.selectedCourse 
                                                ?   <>
                                                        {
                                                            selectedBody === 'course_content'
                                                                ?   <SelectedCourseBody 
                                                                        course={coursesState.selectedCourse} 
                                                                        handleChapterDelete={(chapter) => setDeleteChapter({open: true, chapter: chapter})} 
                                                                        handleChapterEdit={(chapter) => setAddOrEditChapter({open: true, chapter: chapter})}
                                                                        chapterSelected={selectedChapter}
                                                                        handleChapterSelect={(chapter) => setChapterSelected(chapter)}
                                                                        handleDeleteTopic={(topic) => setDeleteTopic({open: true, topic: topic})}
                                                                        handleStartLiveClass={(topic) => setLiveClass({open: true, topic: topic})}
                                                                        handleEditTopic={(topic) => {topic.type === 'quiz' ? setAddOrEditQuiz({open: true, topic: topic, type: topic.type}) : setAddOrEditTopic({open: true, topic: topic, type: topic.type})} }
                                                                    />
                                                                :   <ManageCourseStudents 
                                                                        fetchStudents={fetchStudents}
                                                                        setFetchStudents={setFetchStudents}
                                                                        setProgress={setProgress}
                                                                    />
                                                        }
                                                    </>
                                                : <div className={classes.loader}><CircularProgress /></div> 
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Fragment>   
                    :   null
            }
        </Fragment>
    );
}

export default CourseSelected;
