import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button as MuiButton,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Checkbox,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as actionTypes from '../../Store/actions/actionTypes';
import Button from '../../UIComponents/Button';
import { BsUpload } from 'react-icons/bs';

const useStyles = makeStyles(theme => ({
    title: {
        background: theme.palette.background,
        padding: 16,
        textAlign: 'center',
        fontWeight: 700,
        color: '#fff',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    input: {
        display: 'none',
    },
    dialogRoot: {
        borderRadius: 20,
    },
    btnContainer: {
        display: 'flex',
        background: '#fff',
        width: '100%',
        padding: '0px 16px 16px 16px',
        justifyContent: 'space-around',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20
    }
}))

export default function AddCourseDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [restricted, setRestricted] = useState(false);
    const [description, setDescription] = useState('');

    useEffect(() => {
        if(props.course !== undefined && props.course !== null) {
            props.course.name && setTitle(props.course.name);
            props.course.restricted && setRestricted(props.course.restricted);
            props.course.description && setDescription(props.course.description);
        } else {
            clearForm();
        }
    }, [props.course])

    const handleAddOrEditCourse = () => {
        let newImage = false;
        const payload = {
            deleted: false,
            image: image,
            name: title,
            restricted: restricted,
            type: 'offline',
            isLive: false,
            description: description ? description : '',
        }

        if(props.course){
            payload.id = props.course.id;
            payload.isLive = props.course.isLive;
            payload.chapters = props.course.chapters ? props.course.chapters : null;
        }

        if(props.course !== undefined && props.course !== null){
            newImage = payload.image === null ? false : true;
            payload.image = payload.image === null ? props.course.image : image;
        }

        let error = false;
        if(image === null){
            if(props.course === undefined || props.course === null)
                error = true;
        }
        if(title === ''){
            error = true;
        }
        if(restricted && description === ''){
            error = true;
        }

        if(error){
            dispatch({
                type: actionTypes.SET_ERROR,
                error: true,
                errorMessage: 'Invalid Inputs! Please fill all the mandatory fields.'
            })
        } else {
            if(props.course !== undefined && props.course !== null){
                props.handleCourseEdit(payload, props.course.firebaseRefId, newImage, props.course);
            } else {
                props.handleAddCourse(payload);
            }
        }
    }

    const clearForm = () => {
        setTitle('');
        setRestricted(false);
        setDescription('');
        setImage(null);
    }

    const handleClose = () => {
        clearForm();
        props.handleDialogClose();
    }

    return (
        <Dialog open={props.open} onClose={handleClose} PaperComponent={classes.dialogRoot}>
            <div style={{background: '#fff', borderRadius: 20}}>
                    <Typography variant='body1' className={classes.title}>{ props.course !== undefined && props.course !== null ? 'Edit Course' : 'Add Course' }</Typography>
                    <DialogContent style={{padding: 32, maxWidth: 500}}>
                    <TextField
                        autoFocus
                        value={title}
                        margin="dense"
                        variant='outlined'
                        onChange={(e) => setTitle(e.target.value)}
                        id="name"
                        label="Course Name"
                        fullWidth
                    />
                    <input
                        accept="image/*"
                        className={classes.input}
                        onChange={(e) => setImage(e.target.files[0])}
                        id="contained-button-file"
                        type="file"
                    />
                    <label htmlFor="contained-button-file">
                        <MuiButton variant='outlined' color="primary" component="span" fullWidth style={{margin: '8px 0px'}}>
                            {
                                image !== null
                                    ?   `Change Image`
                                    :   `${props.course !== undefined && props.course !== null ? 'Change Image' : 'Upload Image'}`
                            }
                            <BsUpload size='12px' color='#07AFD4' style={{marginLeft: 8}} />
                        </MuiButton>
                    </label>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={restricted}
                            onChange={(e) => setRestricted(e.target.checked)}
                            color="primary"
                        />
                        }
                        label="Is a restricted course."
                    />
                    {
                        restricted === true
                            ?   <TextField
                                    autoFocus
                                    value={description}
                                    margin="dense"
                                    variant='outlined'
                                    autoComplete="off"
                                    onChange={(e) => setDescription(e.target.value)}
                                    multiline
                                    rowsMax={4}
                                    label="Course Description"
                                    fullWidth
                                />
                            :   null
                    }
                    </DialogContent>
                    <DialogActions>
                    <div className={classes.btnContainer}>
                        <Button btntype='outlinedPrimaryButton' onClick={props.handleDialogClose} fullWidth style={{margin: '0px 16px'}}>Cancel</Button>
                        <Button onClick={handleAddOrEditCourse} fullWidth style={{margin: '0px 16px'}}>
                            {
                                props.course !== undefined && props.course !== null ? 'Update' : 'Create'
                            }
                        </Button>
                    </div>
                    </DialogActions>
                </div>
        </Dialog>
    );
}
