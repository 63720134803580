import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, CircularProgress, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as actionCreators from '../../Store/actions/actionCreators';
import * as actionTypes from '../../Store/actions/actionTypes';
import CourseSelectedOnline from './CourseSelectedOnline';

import AppBar from '../AppBar/AppBar';
import NavDriver from '../../Components/NavDriver/NavDriver';

import Courses from './Courses';
import CourseSelected from './CourseSelected';

import firebase from '../../firebase';

const useStyles = makeStyles(theme => ({
    loader: {
        width: '100%',
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dialogRoot: {
        borderRadius: 20,
    },
}))

const Admin = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const courseState = useSelector(state => state.courses);
    const authState = useSelector(state => state.auth);

    const [loading, setLoading] = useState(true);
    const [courseSelectedOnline, setCourseSelectedOnline] = useState(false);

    // fall back to -> '/' if user is not logged in.
    useEffect(() => {
        if(!authState.loggedIn){
            window.location = '/'
        }
    }, [authState])

    useEffect(() => {
        if(!authState.loggedIn){
            window.location = '/'
        } else {
            if(courseState.courses === null){
                const portal = authState.user.adminData.portal;
                dispatch(actionCreators.getAllCourses(portal));
            } else {
                loading && setLoading(false)
            }
        }
        // eslint-disable-next-line
    }, [courseState])

    useEffect(() => {
        if(courseState.courses){
            const portal = authState.user.adminData.portal;
            let update = false;
            const newCourses = [];
            courseState.courses.forEach(course => {
                if(course.restricted === true && course.chapters){
                    update = true;
                    const newCourse = {...course, chapters: null};
                    newCourses.push(newCourse);
                    const dbPath = `${portal}/paid_courses/courses/${course.id}`;
                    const restrictedCourseRef = firebase.database().ref(dbPath);
                    restrictedCourseRef.set(course);
                } else {
                    newCourses.push(course);
                }
            })
            if(update){
                updateCourses(newCourses, portal);
            }
        }
        // eslint-disable-next-line
    }, [courseState])

    const updateCourses = (newCourses, portal) => {
        const dbPath = `${portal}/data/courses`;
        const ref = firebase.database().ref(dbPath);
        ref.set(newCourses);

        dispatch({
            type: actionTypes.GET_ALL_COURSES,
            courses: newCourses
        })
    }

    const handleOnlineCourseClose = () => {
        setCourseSelectedOnline(false)
        const portal = authState.user.adminData.portal;
        dispatch(actionCreators.updateSelectedCourseContent(null, portal));
    }

    const getBody = () => {
        if(courseState.selectedCourse !== null && courseState.selectedCourse.type === 'online'){
            courseSelectedOnline === false && setCourseSelectedOnline(true)
            return <Courses />
        } else if(courseState.selectedCourse !== null && courseState.selectedCourse.type === 'offline'){
            return <CourseSelected />
        }
        return <Courses />
    }

    return (
        <Fragment>
            <AppBar />
            {
                courseSelectedOnline === true 
                    ?   <Dialog open={courseSelectedOnline} onClose={handleOnlineCourseClose} PaperComponent={classes.dialogRoot}>
                            <CourseSelectedOnline course = {courseState.selectedCourse} handleCloseDialog={handleOnlineCourseClose}/>
                        </Dialog>
                    :   null
            }
            <Grid container>
                <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                    <NavDriver />
                </Grid>
                <Grid item xs={8} sm={9} md={10} lg={10} xl={10}>
                    { loading === true ? <div className={classes.loader}><CircularProgress /></div> : getBody() }    
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default Admin;
