import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Navigation from './Containers/Navigation/Navigation';
import GeneralizedSnackBar from './Containers/GeneralizedSnackBar/GeneralizedSnackBar';
import Footer from './Components/Footer/Footer';

import './App.css';

const font = " 'Roboto', sans-serif ";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: font
  },
  palette: {
    background: 'linear-gradient(#06AED5, #3ACEAF)',
    primary: {main: '#07AFD4'}
  },
  background: {
    paper: '#fff',
    default: "#fff"
  },
})

function App() {
  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
          <GeneralizedSnackBar />
          <Navigation />
          <Footer />
      </MuiThemeProvider>
    </div>
  );
}

export default App;
