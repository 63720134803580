import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Card,
    IconButton,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Typography,
    Dialog
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import truncate from "truncate-html";
import UpdateIsRestrictedDialogContent from "./UpdateIsRestrictedDialogContent";
import UpdateIsLiveDialogContent from './UpdateIsLiveDialogContent';

import { RiDeleteBinLine, RiBroadcastLine } from 'react-icons/ri';
import { FaEdit } from 'react-icons/fa';
import { MdBlock } from 'react-icons/md';
import firebase from '../../firebase';

const useStyles = makeStyles({
    root: {
        // maxWidth: 345,
        position: 'relative',
        // borderRadius: 10
    },
    cardActionArea: {

    },
    cardContent: {
        position: 'absolute',
        padding: 8
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    dialogRoot: {
        borderRadius: 20
    }
});

export default function CourseCard(props) {
    const classes = useStyles();

    const authState = useSelector(state => state.auth);

    const [updateIsLive, setUpdateIsLive] = useState(null);
    const [updateRestricted, setUpdateRestricted] = useState(null);

    const [url, setUrl] = useState('');

    const handleCourseRestrictedEdit = (course) => {
        const newCourse = { ...course };
        newCourse.restricted = course.restricted !== undefined ? !course.restricted : true;
        setUpdateRestricted(null);
        props.handleIsRestrictedEdit(newCourse);
    }

    const handleCourseIsLiveEdit = (course) => {
        const newCourse = { ...course };
        newCourse.isLive = course.isLive !== undefined ? !course.isLive : true;
        setUpdateIsLive(null);
        props.handleIsLiveEdit(newCourse);
    }

    if(authState.loggedIn){
        firebase.storage().ref(authState.user.adminData.portal+'/images/'+props.course.image).getDownloadURL().then(function (url) {
            setUrl(url);
        });
    }

    return (
        <Fragment>
            <Dialog
                open={updateIsLive === null ? false : true}
                onClose={() => setUpdateIsLive(null)}
                PaperComponent={classes.dialogRoot}
            >
                <UpdateIsLiveDialogContent
                    handleCloseDialog={() => setUpdateIsLive(null)}
                    handleCourseEdit={handleCourseIsLiveEdit}
                    course={updateIsLive}
                />
            </Dialog>
            <Dialog
                open={updateRestricted === null ? false : true}
                onClose={() => setUpdateRestricted(null)}
                PaperComponent={classes.dialogRoot}
            >
                <UpdateIsRestrictedDialogContent
                    handleCloseDialog={() => setUpdateRestricted(null)}
                    handleCourseEdit={handleCourseRestrictedEdit}
                    course={updateRestricted}
                />
            </Dialog>
            <Card className={classes.root}>
                <CardActionArea className={classes.cardActionArea} onClick={() => props.handleCourseSelected(props.course)}>
                    <CardMedia
                        component="img"
                        style={{objectFit:'contain', aspectRatio: 1 }}
                        alt={props.course.name}
                        height="200"
                        image={url}
                        title={props.course.name}
                    />
                    <CardContent className={classes.cardContent}>
                        <Typography gutterBottom variant="body2" style={{fontWeight: 700, textTransform: 'uppercase'}}>
                            {truncate(props.course.name, 15)}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions className={classes.cardActions}>
                    {
                        props.course.restricted === true
                            ? <IconButton size="small" onClick={() => setUpdateRestricted(props.course)}>
                                <MdBlock color="red" />
                            </IconButton>
                            : <IconButton size="small" onClick={() => setUpdateRestricted(props.course)}>
                                <MdBlock color="grey" />
                            </IconButton>
                    }
                    {
                        props.course.isLive === true
                            ? <IconButton size="small" onClick={() => setUpdateIsLive(props.course)}>
                                <RiBroadcastLine color="green" />
                            </IconButton>
                            : <IconButton size="small" onClick={() => setUpdateIsLive(props.course)}>
                                <RiBroadcastLine color="grey" />
                            </IconButton>
                    }
                    <IconButton size="small" color="primary" onClick={() => props.handleCourseEdit(props.course)}>
                        <FaEdit color='#07AFD4' />
                    </IconButton>
                    <IconButton size="small" color="primary" onClick={() => props.handleCourseDelete(props.course)}>
                        <RiDeleteBinLine color='#F25136' />
                    </IconButton>
                </CardActions>
            </Card>
        </Fragment>
    );
}
