import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
// import ReactPlayer from 'react-player/youtube';
import Button from './Button';
import truncate from "truncate-html";

import { RiDeleteBinLine } from 'react-icons/ri';
import { FaEdit, FaFilePdf, FaBroadcastTower } from 'react-icons/fa';
import firebase from '../firebase';
import {getYoutubeId} from '../helpers/getIdFromYoutubeLink';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    minHeight: 300,
    position: 'relative'
  },
  cardActionArea: {

  },
  cardContent: {
    position: 'absolute',
    padding: 8
  },
  cardActions: {
      display: 'flex',
      justifyContent: 'flex-end'
  },
  iframe: {
    width: '100%'
  }
});

export default function CourseCard(props) {
  const classes = useStyles();

  const authState = useSelector(state => state.auth);

  const [url, setUrl] = useState('');

  if(authState.loggedIn){
    firebase.storage().ref(authState.user.adminData.portal+'/images/'+props.topic.image).getDownloadURL().then(function (url) {
      setUrl(url);
    });
  }

  const openNotes = () => {
    firebase.storage().ref(authState.user.adminData.portal+'/notes/'+props.topic.additional_notes).getDownloadURL().then(function (url) {
        window.open(`${url}`)
    }).catch((error) => { throw error });
  }


  return (
    <Card className={classes.root}>
        <CardActionArea className={classes.cardActionArea}>
            {
                props.topic.type === 'video' || props.topic.type === 'live'
                    ?   props.topic.video
                            // eslint-disable-next-line
                        ?   <iframe className={classes.iframe} src={'https://www.youtube.com/embed/'+getYoutubeId(props.topic.video)} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> 
                        :   <CardMedia
                                component="img"
                                alt={props.topic.name}
                                height="140"
                                image={url}
                                title={props.topic.name}
                                fullWidth
                            />
                    :   null   
            }
            {
                props.topic.type === 'notes'
                    ?   <CardMedia
                            component="img"
                            alt={props.topic.name}
                            height="140"
                            image={url}
                            title={props.topic.name}
                        />
                    :   null
            }
            {
                props.topic.type === 'quiz'
                    ?   <CardMedia
                            component="img"
                            alt={props.topic.name}
                            height="140"
                            image={url}
                            title={props.topic.name}
                        />
                    :   null
            }
            {/* <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="body2">
                    {props.topic.name}
                </Typography>
            </CardContent> */}
        </CardActionArea>
        <CardActions className={classes.cardActions}>
            { props.topic.type === 'live' ? 
                <IconButton size="small" color="primary" onClick={() => props.handleStartLiveClass(props.topic)}>
                    <FaBroadcastTower  color='green'/>
                </IconButton>
            : null
            }
            <IconButton size="small" color="primary" onClick={() => props.handleEditTopic(props.topic)}>
                <FaEdit  color='#07AFD4'/>
            </IconButton>
            <IconButton size="small" color="primary" onClick={() => props.handleDeleteTopic(props.topic)}>
                <RiDeleteBinLine  color='#F25136'/>
            </IconButton>
        </CardActions>
        <div style={{borderTop: '1px solid #eee', padding: 4}}>
            <div>
                <Typography variant="caption" style={{fontWeight: 700}}>
                    {truncate(props.topic.name, 25)}
                </Typography>
            </div>
            <Typography variant="caption">
                {truncate(props.topic.description, 80)}
            </Typography>
        </div>
        {
            props.topic.additional_notes
                ?   <div style={{padding: 4, width: '100%', textAlign: 'center', position: 'absolute', bottom: '6px'}}>
                        <Button btntype='containedPrimaryButton' onClick={openNotes}>
                            View Document <FaFilePdf color='#b71c1c' style={{marginLeft: '2px'}}/>
                        </Button>
                    </div>
                :   null
        }
    </Card>
);
}
