import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from '../../UIComponents/Button';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 350,
        borderRadius: 20
    },
    topBar: {
        background: theme.palette.background,
        width: '100%',
        textAlign: 'center',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    topBarText: {
        color: '#fff',
        fontWeight: 700,
        padding: 8
    },
    btnContainer: {
        display: 'flex',
        background: '#fff',
        padding: 16,
        justifyContent: 'space-around',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20
    }
}))

const CourseSelectedOnline = props => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.topBar}>
                <Typography variant='body1' className={classes.topBarText}>Online Course</Typography>
            </div>
            <div style={{padding: '16px', background: '#fff'}}>
                <Typography variant='body2' style={{fontWeight: 700, textTransform: 'uppercase'}}>{props.course.name}</Typography>
                <br />
                <Typography variant='body2'>{props.course.description}</Typography>
            </div>
            <div className={classes.btnContainer}>
                <Button btntype='outlinedPrimaryButton' onClick={props.handleCloseDialog}>Close</Button>
            </div>
        </div>
    );
}

export default CourseSelectedOnline;