import React, { useState, useEffect } from 'react';
import {
    Button as MuiButton,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as actionTypes from '../../Store/actions/actionTypes';
import { useDispatch } from 'react-redux';
import Button from '../../UIComponents/Button';
import { BsUpload } from 'react-icons/bs';

const useStyles = makeStyles(theme => ({
    title: {
        background: theme.palette.background,
        padding: 16,
        textAlign: 'center',
        fontWeight: 700,
        color: '#fff',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    input: {
        display: 'none',
    },
    dialogRoot: {
        borderRadius: 20,
    },
    btnContainer: {
        display: 'flex',
        background: '#fff',
        width: '100%',
        paddingBottom: 16,
        justifyContent: 'space-around',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20
    },
}))

export default function AddOrEditChapterDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [description, setDescription] = useState('');

    useEffect(() => {
        if(props.chapter) {
            props.chapter.name && setTitle(props.chapter.name);
            // props.chapter.image && setImage(props.chapter.image);
            props.chapter.description && setDescription(props.chapter.description);
        } else {
            setTitle('');
            setImage(null);
            setDescription('');
        }
    }, [props.chapter])

    const handleAddOrEditChapter = () => {
        let newImage = false;
        const payload = {
            name: title,
            image: image,
            description: description,
            deleted: false
        }
        let error = false;
        if(payload.name === ''){
            error = true;
        }
        if(props.chapter){
            if(payload.image !== null){
                newImage = true;
            } else {
                payload.image = props.chapter.image;
            }
        } else {
            if(payload.image === null){
                error = true;
            }
        }
        if(payload.description === ''){
            error = true;
        }
        if(error === true){
            dispatch({
                type: actionTypes.SET_ERROR,
                error: true,
                errorMessage: 'Invalid Inputs! Please fill all the mandatory fields.'
            })
        } else {
            if(props.chapter){
                props.handleChapterEdit(payload, props.chapter.id, newImage);
            } else {
                props.handleAddNewChapter(payload);
            }
        }
    }

    return (
        <Dialog open={props.open} onClose={props.handleDialogClose} PaperComponent={classes.dialogRoot}>
            <div style={{background: '#fff', borderRadius: 20}}>
                    <Typography variant='body1' className={classes.title}>{props.chapter ? 'Edit Chapter' : 'Add Chapter'}</Typography>
                    <DialogContent style={{padding: '32px'}}>
                    <TextField
                        autoFocus
                        value={title}
                        margin="dense"
                        variant='outlined'
                        onChange={(e) => setTitle(e.target.value)}
                        id="name"
                        label="Chapter Name"
                        fullWidth
                    />
                    <TextField
                        value={description}
                        margin="dense"
                        variant='outlined'
                        onChange={(e) => setDescription(e.target.value)}
                        id="name"
                        label="Chapter Description"
                        multiline
                        rowsMax={4}
                        fullWidth
                    />
                    <input
                        accept="image/*"
                        className={classes.input}
                        onChange={(e) => setImage(e.target.files[0])}
                        id="contained-button-file"
                        type="file"
                    />
                    <label htmlFor="contained-button-file">
                        <MuiButton variant='outlined' color="primary" component="span" fullWidth style={{margin: '8px 0px'}}>
                            {
                                image !== null
                                    ?   `Change Image`
                                    :   `${props.chapter !== undefined && props.chapter !== null ? 'Change Image' : 'Upload Image'}`
                            }
                            <BsUpload size='12px' color='#07AFD4' style={{marginLeft: 8}} />
                        </MuiButton>
                    </label>
                    </DialogContent>
                    <DialogActions>
                    {/* <Button onClick={props.handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddOrEditChapter} color="primary">
                        {
                            props.chapter ? 'Update' : 'Create'
                        }
                    </Button> */}
                    <div className={classes.btnContainer}>
                        <Button btntype='outlinedPrimaryButton' onClick={props.handleDialogClose} fullWidth style={{margin: '0px 22px'}}>Cancel</Button>
                        <Button onClick={handleAddOrEditChapter} fullWidth style={{margin: '0px 22px'}}>
                            {
                                props.chapter ? 'Update' : 'Create'
                            }
                        </Button>
                    </div>
                    </DialogActions>
                </div>
        </Dialog>
    );
}
