import React, { useEffect } from "react"
import { Router, Switch, Route } from "react-router-dom";
import { useSelector } from 'react-redux';

import History from '../../Utils/History';
import LandingPage from '../LandingPage/LandingPage';
import Login from '../Login/Login';
import Admin from '../Admin/Admin'
import Error404 from '../../Components/Error404/Error404';

const Navigation = props => {
    const authState = useSelector(state => state.auth);

    useEffect(() => {
      if(authState.loggedIn){
        if(window.location.pathname !== '/admin'){
          window.location = '/admin';
        }
      }
      // eslint-disable-next-line
    }, [])

    return (
      <Router history={History}>
        <Switch>
          <Route path = "/" exact component={LandingPage} />
          <Route path = "/Login" exact component={Login} />
          <Route path = "/admin" exact component={Admin} />
          <Route component={Error404} />
        </Switch>
      </Router>
    )
}

export default Navigation;