import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
    loggedIn: false,
    user: null,
}

const login = (state, action) => {
    return updateObject(state, {
        loggedIn: true,
        user: action.user
    })
}

const logout = (state, action) => {
    return updateObject(state, {
        loggedIn: false,
        user: null
    })
}

const authReducer = (state = initialState , action) => {
    switch(action.type){
        case actionTypes.LOGIN: return login(state, action);
        case actionTypes.LOGOUT: return logout(state, action);
        default: return state;
    }
}

export default authReducer;
