import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, 
    Grid, 
    Typography, 
    IconButton, 
    TextField, 
    Paper, 
    Button, 
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MdClose, MdAdd } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import DeleteDialog from '../../Containers/Admin/DeleteCourseDialog';
import * as actionTypes from '../../Store/actions/actionTypes';
import Reorder, { reorder } from 'react-reorder';
import firebase from '../../firebase';
import { BsUpload } from 'react-icons/bs';

import * as actionCreators from '../../Store/actions/actionCreators';

const useStyles = makeStyles(theme => ({
    drawer: {
        height: '100vh',
        background: '#fff'
    },
    appBar: {
        background: theme.palette.background,
        padding: '8px 16px'
    },
    paper: {
        padding: 8
    },
    input: {
        display: 'none',
    },
    // inputContainer: {
    //     background: 'rgba(7, 175, 212, 0.05)', 
    //     borderRadius: 4,
    //     padding: 8
    // },
    formControlSelect: {
        width: '100%'
    },
    question: {
        display: 'flex'
    }
}))

const AddOrEditQuiz = props => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const coursesState = useSelector(state => state.courses);
    const authState = useSelector(state => state.auth);

    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [description, setDescription] = useState('');

    const [optionOne, setOptionOne] = useState('');
    const [optionOneImage, setOptionOneImage] = useState(null);
    const [optionTwo, setOptionTwo] = useState('');
    const [optionTwoImage, setOptionTwoImage] = useState(null);
    const [optionThree, setOptionThree] = useState('');
    const [optionThreeImage, setOptionThreeImage] = useState(null);
    const [optionFour, setOptionFour] = useState('');
    const [optionFourImage, setOptionFourImage] = useState(null);
    const [correctAnswer, setCorrectAnswer] = useState(1);
    const [questions, setQuestions] = useState([]);
    const [questionDescription, setQuestionDescription] = useState('');
    const [points, setPoints] = useState(1);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [deleteQuestion, setDeleteQuestion] = useState({delete: false, que: null});

    useEffect(() => {
        if(selectedQuestion){
            selectedQuestion.choices[0].text_choice && setOptionOne(selectedQuestion.choices[0].text_choice);
            selectedQuestion.choices[0].image_choice && setOptionOneImage(selectedQuestion.choices[0].image_choice);
            selectedQuestion.choices[1].text_choice && setOptionTwo(selectedQuestion.choices[1].text_choice);
            selectedQuestion.choices[1].image_choice && setOptionTwoImage(selectedQuestion.choices[1].image_choice);
            selectedQuestion.choices[2].text_choice && setOptionThree(selectedQuestion.choices[2].text_choice);
            selectedQuestion.choices[2].image_choice && setOptionThreeImage(selectedQuestion.choices[2].image_choice);
            selectedQuestion.choices[3].text_choice && setOptionFour(selectedQuestion.choices[3].text_choice);
            selectedQuestion.choices[3].image_choice && setOptionFourImage(selectedQuestion.choices[3].image_choice);
            selectedQuestion.description && setQuestionDescription(selectedQuestion.description);
            selectedQuestion.correct_choice && setCorrectAnswer(selectedQuestion.correct_choice);
            selectedQuestion.points && setPoints(selectedQuestion.points)
        }
    }, [selectedQuestion])

    useEffect(() => {
        if(props.topic !== null && props.topic !== undefined) {
            if(props.topic.questions){
                if(props.topic.questions.length >= 1){
                    const nonDeletedQuestions = props.topic.questions.filter(q => q.deleted !== true);
                    if(selectedQuestion === null && nonDeletedQuestions.length >= 1){
                        setSelectedQuestion(nonDeletedQuestions[0]);
                    } else {
                        if(nonDeletedQuestions.length >= 1){
                            const newSelectedQue = nonDeletedQuestions.filter(q => q.id === selectedQuestion.id)
                            if(newSelectedQue.length === 1){
                                setSelectedQuestion(newSelectedQue[0]);
                            }
                        }
                    }
                }
                setQuestions(props.topic.questions);
                setTitle(props.topic.name);
                setDescription(props.topic.description);
                setImage(props.topic.image);
            }
        } else {
            handleClearForm();
            setTitle('');
            setImage(null);
            setDescription('');
            setSelectedQuestion(null);
        }
        // eslint-disable-next-line
    }, [props.topic])

    const handleClearForm = () => {
        setOptionOne('');
        setOptionOneImage(null);
        setOptionTwo('');
        setOptionTwoImage(null);
        setOptionThree('');
        setOptionThreeImage(null);
        setOptionFour('');
        setOptionFourImage(null);
        setQuestionDescription('');
        setCorrectAnswer(1);
        setPoints(1)
    }

    const handleAddOrEditQuiz = () => {
        let newQuestions = [...questions];
        let questionId = null;
        const newQuestion = {
            description: questionDescription,
                correct_choice: correctAnswer,
                points: points,
                choices: [
                    {
                        id: 1,
                        text_choice: optionOne,
                        image_choice: optionOneImage
                    }, 
                    {
                        id: 2,
                        text_choice: optionTwo,
                        image_choice: optionTwoImage
                    }, 
                    {
                        id: 3,
                        text_choice: optionThree,
                        image_choice: optionThreeImage
                    }, 
                    {
                        id: 4,
                        text_choice: optionFour,
                        image_choice: optionFourImage
                    }
                ],
        }
        if(selectedQuestion){
            questions.forEach((question, i) => {
                if(question.id === selectedQuestion.id){
                    questionId = question.id;
                    newQuestions[i] = {
                        ...question,
                        ...newQuestion
                    }
                }
            })
        } else {
            questionId = questions.length + 1
            newQuestions.push({
                id: questionId,
                ...newQuestion
            })
            setSelectedQuestion({
                id: questionId,
                ...newQuestion
            })
        }
        const payload = {
            name: title,
            description: description,
            image: image,
            questions: newQuestions,
            type: 'quiz',
            deleted: false
        }

        if(props.topic !== null){
            payload.id = props.topic.id
        }

        let error = false;
        if(payload.name === ''){
            error = true
        }
        if(payload.description === ''){
            error = true
        }
        newQuestion.choices.forEach(option => {
            if(option.choice_text === '' && option.choice_image === null){
                error = true
            }
        })
        if(error){
            dispatch({
                type: actionTypes.SET_ERROR,
                error: true,
                errorMessage: 'Invalid Inputs! Please fill all the mandatory fields.'
            })
        } else {
            props.handleAddnewTopic(payload, questionId);
        }
    }

    const handleQuestionDelete = (que) => {
        const newQuestions = []
        let questionId = null
        props.topic.questions.forEach((q) => {
            if(q.id === que.id){
                questionId = q.id;
                newQuestions.push({
                    ...que,
                    deleted: true
                })
            } else {
                newQuestions.push(q)
            }
        })
        const payload = {
            ...props.topic,
            questions: newQuestions
        }
        props.handleAddnewTopic(payload, questionId);
        setDeleteQuestion({delete: false, que: null});
    }

    const handleAddNewQuestion = () => {
        setSelectedQuestion(null);
        handleClearForm();
    }

    const onReorder = (event, previousIndex, nextIndex) => {
        const nonDeletedQues = props.topic.questions.filter(q => q.deleted !== true);
        const newQuestions = [...reorder(nonDeletedQues, previousIndex, nextIndex), ...props.topic.questions.filter(q => q.deleted === true)];
        const newTopic = {...props.topic};
        newTopic.questions = newQuestions;
        const newChapter = {...props.chapterSelected};
        const newTopics = [];
        props.chapterSelected.topics.forEach(t => {
            if(t.id === newTopic.id){
                newTopics.push(newTopic)
            } else {
                newTopics.push(t)
            }
        })
        newChapter.topics = [...newTopics];
        const courses = [];
        let newCourse = null;
        let refId = null;
        coursesState.courses.forEach(course => {
            if(course.id === coursesState.selectedCourse.id){
                refId = coursesState.selectedCourse.refId;
                const newChapters = [];
          
                let chaptersToMap = course.chapters ? course.chapters : [];
                if(course.restricted === true){
                    let resCourse = coursesState.restrictedCourses.filter(c => c.id === course.id)[0];
                    chaptersToMap = resCourse.chapters ? resCourse.chapters : [];
                }

                chaptersToMap.forEach(chapter => {
                    chapter.id === newChapter.id ? newChapters.push(newChapter) : newChapters.push(chapter);
                })
                newCourse = {
                    ...course,
                    chapters: newChapters
                }
                courses.push({...newCourse, firebaseRefId: null})
            } else {
                courses.push({...course, firebaseRefId: null});
            }
        })
        const portal = authState.user.adminData.portal;
        const coursesRef = firebase.database().ref(portal + '/data/courses');
        coursesRef.set([...courses]);
        dispatch(actionCreators.updateSelectedCourseContent({...newCourse, firebaseRefId: refId}, portal));
        dispatch({
            type: actionTypes.GET_ALL_COURSES,
            courses: [...courses]
        })
        props.handleChapterSelect(newChapter);
        props.topicReorderStateUpdate(newTopic);
    }

    const getQuestionList = () => {
        if(props.topic){
            return (
                <div style={{width: '100%', minHeight: '40vh', maxHeight: '40vh', overflowX: 'auto'}}>
                    <Reorder
                        reorderId="my-questions"
                        // reorderGroup="reorder-questions"
                        // lock="horizontal"
                        touchHoldTime={200}
                        holdTime={100}
                        onReorder={onReorder}
                        placeholder={
                            <div item style={{ border: '1px dashed #aaa', background: '#cfd8dc', padding: 4, height: 30 }} />
                        }
                    >
                    {
                        props.topic.questions.map(que => {
                            return (
                                <div key={que.id} id='my-questions'>
                                    {
                                        que.deleted !== true
                                            ?   <div className={classes.question} style={selectedQuestion !== null && que.id === selectedQuestion.id ? {border: '1px solid #07AFD4', margin: '4px 0px', padding: 2, background: 'rgba(7, 175, 212, 0.05)'} : {margin: '4px 0px', padding: 2, border: '1px solid #07AFD4'}} onClick={() => setSelectedQuestion(que)}>
                                                    <div style={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
                                                        <Typography variant='caption'>{que.description}</Typography>
                                                    </div>
                                                    <div>
                                                        <IconButton size="small" color="primary" onClick={() => setDeleteQuestion({delete: true, que: que})}>
                                                            <RiDeleteBinLine  color='#F25136'/>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            :   null
                                    }
                                </div>
                            )
                        })
                    }
                    </Reorder>
                </div>
            ) 
        } else {
            return (
                <div style={{width: '100%', minHeight: '40vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='body2'>No Questions Added Yet.</Typography>
                </div>
            )
        }
    }

    return (
        <Drawer anchor='bottom' open={props.open} onClose={props.handleDialogClose} style={{overflowY: 'hidden'}}>
            <DeleteDialog 
                open={deleteQuestion.delete}
                deleteCourse={deleteQuestion.que}
                handleCloseDialog={() => setDeleteQuestion({delete: false, que: null})}
                handleCourseDelete={handleQuestionDelete}
            />
            <div className={classes.drawer}>
            <div className={classes.appBar}>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{display: 'flex', alignItems: 'center'}}>
                        <Typography variant='body1' style={{color: '#fff', fontWeight: 700}}>{props.topic ? 'Edit Quiz' : 'Add Quiz'}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <IconButton onClick={props.handleDialogClose}>
                            <MdClose color='#fff'/>
                        </IconButton>
                    </Grid>
                </Grid>   
            </div>
             <Grid container spacing={2} style={{padding: 16, maxWidth: window.innerWidth, overflowY: 'hidden'}}>
                 <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <Paper className={classes.paper}>
                        <div>
                            <Typography variant='body1' style={{fontWeight: 700}}>Quiz</Typography>
                        </div>
                        <div>
                            <TextField
                                autoFocus
                                value={title}
                                margin="dense"
                                variant='outlined'
                                onChange={(e) => setTitle(e.target.value)}
                                id="name"
                                label="Quiz Title"
                                fullWidth
                            />
                        </div>
                        <div>
                            <TextField
                                value={description}
                                margin="dense"
                                variant='outlined'
                                onChange={(e) => setDescription(e.target.value)}
                                id="name"
                                label="Quiz Description"
                                fullWidth
                            />
                        </div>
                        <div>
                            <input
                                accept="image/*"
                                className={classes.input}
                                onChange={(e) => setImage(e.target.files[0])}
                                id="contained-button-file-image"
                                type="file"
                            />
                            <label htmlFor="contained-button-file-image">
                                <Button variant='outlined' color="primary" component="span" fullWidth style={{margin: '8px 0px'}}>
                                    {
                                        image !== null
                                            ?   `Change Image`
                                            :   'Upload Image'
                                    }
                                    <BsUpload size='12px' color='#07AFD4' style={{marginLeft: 8}} />
                                </Button>
                            </label>
                        </div>
                    </Paper>
                    <br />
                    <Paper className={classes.paper}>
                        <div style={{display: 'flex', padding: 4}}>
                            <Typography variant='body1' style={{fontWeight: 700, flexGrow: 1}}>Questions</Typography>
                            <IconButton onClick={handleAddNewQuestion} size="small">
                                <MdAdd />
                            </IconButton>
                         </div>
                        {getQuestionList()}
                    </Paper>
                 </Grid>
                 <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                     <Paper className={classes.paper}>
                        <Typography variant='body1' style={{fontWeight: 700}}>Question</Typography>
                        <br />
                        <div>
                            <TextField
                                value={questionDescription}
                                margin="dense"
                                variant='outlined'
                                onChange={(e) => setQuestionDescription(e.target.value)}
                                id="name"
                                label="Question Description"
                                multiline
                                rowsMax={4}
                                fullWidth
                            />
                        </div>

                            <Grid container spacing={2} style={{marginTop: 16}}>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <div className={classes.inputContainer}>
                                    <TextField
                                        value={optionOne}
                                        margin="dense"
                                        variant='outlined'
                                        onChange={(e) => setOptionOne(e.target.value)}
                                        id="name"
                                        label="Option One"
                                        fullWidth
                                    />
                                    <input
                                    accept="image/*"
                                    className={classes.input}
                                    onChange={(e) => setOptionOneImage(e.target.files[0])}
                                    id="contained-button-file-option-one"
                                    type="file"
                                    />
                                    <label htmlFor="contained-button-file-option-one">
                                        <Button variant='outlined' color="primary" component="span" fullWidth style={{margin: '8px 0px'}}>
                                            {
                                                optionOneImage !== null
                                                    ?   'Change Image'
                                                    :   'Upload Image'
                                            }
                                            <BsUpload size='12px' color='#07AFD4' style={{marginLeft: 8}} />
                                        </Button>
                                    </label>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <div className={classes.inputContainer}>
                                    <TextField
                                        value={optionTwo}
                                        margin="dense"
                                        variant='outlined'
                                        onChange={(e) => setOptionTwo(e.target.value)}
                                        id="name"
                                        label="Option Two"
                                        fullWidth
                                    />
                                    <input
                                    accept="image/*"
                                    className={classes.input}
                                    onChange={(e) => setOptionTwoImage(e.target.files[0])}
                                    id="contained-button-file-option-two"
                                    type="file"
                                    />
                                    <label htmlFor="contained-button-file-option-two">
                                        <Button variant='outlined' color="primary" component="span" fullWidth style={{margin: '8px 0px'}}>
                                            {
                                                optionTwoImage !== null
                                                    ?   `Change Image`
                                                    :   'Upload Image'
                                            }
                                            <BsUpload size='12px' color='#07AFD4' style={{marginLeft: 8}} />
                                        </Button>
                                    </label>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <div className={classes.inputContainer}>
                                    <TextField
                                        value={optionThree}
                                        margin="dense"
                                        variant='outlined'
                                        onChange={(e) => setOptionThree(e.target.value)}
                                        id="name"
                                        label="Option Three"
                                        fullWidth
                                    />
                                    <input
                                    accept="image/*"
                                    className={classes.input}
                                    onChange={(e) => setOptionThreeImage(e.target.files[0])}
                                    id="contained-button-file-option-three"
                                    type="file"
                                    />
                                    <label htmlFor="contained-button-file-option-three">
                                        <Button variant='outlined' color="primary" component="span" fullWidth style={{margin: '8px 0px'}}>
                                            {
                                                optionThreeImage !== null
                                                    ?   `Change Image`
                                                    :   'Upload Image'
                                            }
                                            <BsUpload size='12px' color='#07AFD4' style={{marginLeft: 8}} />
                                        </Button>
                                    </label>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <div className={classes.inputContainer}>
                                    <TextField
                                        value={optionFour}
                                        margin="dense"
                                        variant='outlined'
                                        onChange={(e) => setOptionFour(e.target.value)}
                                        id="name"
                                        label="Option Four"
                                        fullWidth
                                    />
                                    <input
                                    accept="image/*"
                                    className={classes.input}
                                    onChange={(e) => setOptionFourImage(e.target.files[0])}
                                    id="contained-button-file-option-four"
                                    type="file"
                                    />
                                    <label htmlFor="contained-button-file-option-four">
                                        <Button variant='outlined' color="primary" component="span" fullWidth style={{margin: '8px 0px'}}>
                                            {
                                                optionFourImage !== null
                                                    ?   `Change Image`
                                                    :   'Upload Image'
                                            }
                                            <BsUpload size='12px' color='#07AFD4' style={{marginLeft: 8}} />
                                        </Button>
                                    </label>
                                </div>
                            </Grid>
                        </Grid>
                        <div style={{paddingTop: 16, marginTop: 16, display: 'flex', justifyContent: 'center', background: 'rgba(7, 175, 212, 0.05)', borderRadius: 4, padding: 8 }}>
                            <FormControl component="fieldset">
                            <FormLabel component="legend">Correct Answer</FormLabel>
                            <RadioGroup row aria-label="position" name="position" defaultValue={'1'} value={correctAnswer} onChange={e => setCorrectAnswer(e.target.value)}>
                                <FormControlLabel
                                value={'1'}
                                control={<Radio color="primary" />}
                                label="Option One"
                                labelPlacement="bottom"
                                />
                                <FormControlLabel
                                value={'2'}
                                control={<Radio color="primary" />}
                                label="Option Two"
                                labelPlacement="bottom"
                                />
                                <FormControlLabel
                                value={'3'}
                                control={<Radio color="primary" />}
                                label="Option Three"
                                labelPlacement="bottom"
                                />
                                <FormControlLabel
                                value={'4'}
                                control={<Radio color="primary" />}
                                label="Option Four"
                                labelPlacement="bottom"
                                />
                            </RadioGroup>
                            </FormControl>
                        </div>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', paddingTop: 16, marginTop: 16}}>
                            <Button onClick={handleClearForm} color="primary" style={{marginRight: 16}}>
                                Clear
                            </Button>
                            <Button onClick={handleAddOrEditQuiz} color="primary" variant='contained'>
                                {
                                    selectedQuestion !== null ? 'Update' : 'Create'
                                }
                            </Button>
                        </div>
                        </Paper>
                        </Grid>
                    </Grid>
            </div>
        </Drawer>
    )
}

export default AddOrEditQuiz;
