import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    // background: '#3ACEAC',
    background: 'rgba(7, 175, 212, 0.2)',
    borderRadius: 8
  },
  indicator: {
    backgroundColor: 'transparent',
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    // minWidth: 72,
    background: 'rgba(7, 175, 212, 0.5)',
    color: '#fff',
    margin: 4,
    padding: 4,
    borderRadius: 8,
    // borderRight: '1px solid #eee',
    // fontWeight: theme.typography.fontWeightRegular,
    fontWeight: 600,
    fontFamily: [
      "Source Sans Pro, 'Poppins', sans-serif"
    ].join(','),
    '&:hover': {
      color: '#fff',
      opacity: 1,
    },
    '&$selected': {
      color: '#fff',
      background: '#EAAE21',
      fontWeight: 600,
    }
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

export default function ScrollableTabsButtonAuto(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if(props.courses && props.selectedCourse){
      props.courses.forEach((course, i) => {
        course.firebaseRefId === props.selectedCourse.firebaseRefId && setValue(i);
      })
    }
    // eslint-disable-next-line
  }, [props.selectedCourse])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.handleCourseChange(newValue);
  };

  return (
    <div className={classes.root}>
        <AntTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {
            props.courses.map((course) => {
              return (
                <AntTab label={course.name} key={course.id} />
              )
            })
          }
        </AntTabs>
    </div>
  );
}
