import React, { useState, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography, TextField, Dialog, CircularProgress, withWidth, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import history from '../../Utils/History';
import firebase from '../../firebase';
import * as actionTypes from '../../Store/actions/actionTypes';
import validator from 'validator';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '../../UIComponents/Button';
import PhoneInput from '../../UIComponents/PhoneInput/PhoneInput';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundImage: `url(/assets/Dashboard_login.png)`,
        // backgroundRepeat: 'no-repeat',
    },
    gridItemLeft: {
        padding: 36,
    },
    gridItemRight: {
        padding: 16,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            height: '70vh'
        }
    },
    bold: {
        fontWeight: 700,
    },
    ButtonContainer: {
        margin: '16px 0px',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    progressDialog: {
        background: 'transparent',
        border: '0px',
        boxShadow: 'none',
        overflow: 'hidden'
    },
}))

const LandingPage = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false)
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [adminData, setAdminData] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    // eslint-disable-next-line
    const [country, setCountry] = useState('in'); // unused setCountry

    const handlePhoneInput = (value, country, e, formattedValue) => {
        setPhone(value);
    }

    useEffect(() => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': function(response) {
                // handleSendOtp();
            }
        });
    }, [])


    const handleLoggedIn = (user) => {
        dispatch({
            type: actionTypes.LOGIN,
            user: user
        })
        history.replace({
            pathname: '/admin'
        });
        setLoading(false);
    }

    const handleValidation = () => {
        const error = !validator.isMobilePhone(phone)
        setPhoneError(error)
    }

    const onSignInSubmit = () => {}

    const handleOTPSubmited = () => {
        if(otp === ''){
            dispatch({
                type: actionTypes.SET_ERROR,
                error: true,
                errorMessage: 'Please enter correct OTP.'
            })
            setOtpError(true);
        } else {
            setLoading(true);
            const code = otp
            if(adminData !== null){
                window.confirmationResult.confirm(code).then(function (result) {
                    let user = {
                        displayName: result.user.displayName,
                        email: result.user.email,
                        emailVarified: result.user.emailVarified,
                        phone: result.user.phoneNumber,
                        photoURL: result.user.photoURL,
                        uid: result.user.uid,
                        refreshToken: result.user.refreshToken,
                        adminData: adminData
                    }
                    handleLoggedIn(user);
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.SET_ERROR,
                        error: true,
                        errorMessage: error.code === 'auth/invalid-verification-code' ? 'Please enter correct OTP and try again.' : 'Something went wrong please try again.'
                    })
                    setOtpError(true);
                    setLoading(false);
                });
            } else {
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error: true,
                    errorMessage: 'Something went wrong please try again.'
                })
                setLoading(false);
            }
        }
    }

    const handleSendAgain = () => {
        setOtp('');
        otpError && setOtpError(false);
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': function(response) {
                // handleSendOtp();
            }
        });
        handleSendOtp();
    }

    const handleSendOtp = () => {
        const error = !validator.isMobilePhone(phone)
        setPhoneError(error)
        if(!error){
        setLoading(true);
        const tutorRef = firebase.database().ref('tutors').child(`+${phone}`);
        tutorRef.once('value').then(snapshot => {
            if(snapshot.val() !== null){
                setAdminData(snapshot.val());
                const phoneNumber = `+${phone}`
                let appVerifier = window.recaptchaVerifier;
                firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
                    .then(function (confirmationResult) {
                        setOtpSent(true)
                        setLoading(false);
                        window.confirmationResult = confirmationResult;
                    }).catch(function (error) {
                        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
                            'size': 'invisible',
                            'callback': function(response) {
                              onSignInSubmit();
                            }
                        });
                        setLoading(false)
                        dispatch({
                            type: actionTypes.SET_ERROR,
                            error: true,
                            errorMessage: 'Something went wrong please try again.'
                        })
                        setAdminData(null)
                    });
            } else {
                //user dosenot exist
                setLoading(false);
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error: true,
                    errorMessage: 'There is no account with this phone number. Please contact admin.'
                })
                setAdminData(null);
            }
        })
        .catch(error => {
            setLoading(false);
            dispatch({
                type: actionTypes.SET_ERROR,
                error: true,
                errorMessage: 'Please check your internet connection and try again.'
            })
            setAdminData(null)
        })
        }
    }

    const handleOtpChanged = e => {
        otpError && setOtpError(false);
        setOtp(e.target.value);
    }

    const handleChangePhoneNumber = e => {
        e.preventDefault();
        setPhone('');
        setOtpSent(false);
        setOtp('');
        setPhoneError(false);
        setShowPassword(false);
        setOtpError(false);
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': function(response) {
                // handleSendOtp();
            }
        });
    }

    return (
        <Fragment>
            <Dialog open={loading} PaperComponent={classes.progressDialog}>
                <div style={{padding: 16, overflow: 'hidden'}}>
                    <CircularProgress />
                </div>
            </Dialog>
            {
                props.width === 'xs'
                    ?   null
                    :   props.width === 'sm'
                        ?   null
                        :   <img src="/assets/Dashboard_login.png" alt="" width="100%" height="100vh" style={{objectFit: 'cover', position: 'absolute', width: '100%', height: '100vh'}} />
            }
            {/* <img src="/assets/Dashboard_login.png" alt="" width="100%" height="100vh" style={{objectFit: 'cover', position: 'absolute', width: '100%', height: '100vh'}} /> */}
            <Grid container className={classes.root}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.gridItemLeft}>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.gridItemRight}>
                    <div style={{minWidth: 300}}>
                        <Typography variant='h6' style={{color: '#07AFD4', fontWeight: 700}}>Sign In</Typography>
                        <br />
                        <Typography variant='h6' className={classes.bold} style={{color: '#616161'}}>Welcome User</Typography>
                        <br />
                        <div style={{paddingTop: 16}}>
                            <PhoneInput 
                                country={country}
                                isValid={!phoneError}
                                enableSearch={true}
                                value={phone}
                                onChange={handlePhoneInput}
                                onBlur={handleValidation}
                                disabled={otpSent ? true : false}
                                helperText={phoneError ? 'Please enter a valid phone number.' : ''}
                            />
                        </div>
                        <br />
                        {
                            otpSent
                                ?   <Fragment>
                                        <TextField 
                                            value={otp}
                                            onChange={handleOtpChanged}
                                            fullWidth
                                            error={otpError}
                                            helperText={otpError ? 'Please enter correct otp.' : ''}
                                            type={showPassword ? 'text' : 'password'}
                                            style={{marginTop: 16}}
                                            label='Enter OTP'
                                            InputProps={{
                                                endAdornment: 
                                                    <InputAdornment position="end">
                                                      <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                      >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                      </IconButton>
                                                    </InputAdornment>
                                            }}
                                        />
                                        <div className={classes.ButtonContainer}>
                                            <Button btntype='containedPrimaryButton' onClick={handleOTPSubmited} id='sign-in-button'>Sign In</Button>
                                            <Button btntype='outlinedPrimaryButton' onClick={handleSendAgain} id='sign-in-button' style={{marginLeft: 8}}>Resend OTP</Button>
                                        </div>
                                        <Button btntype='textPrimary' onClick={handleChangePhoneNumber}>Change Phone Number</Button>
                                    </Fragment>
                                :   <div className={classes.ButtonContainer}>
                                        <Button btntype='containedPrimaryButton' onClick={handleSendOtp} id='sign-in-button'>Send Otp</Button>
                                    </div>
                        }      
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default withWidth()(LandingPage);
