import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    containedButton: {
        background: theme.palette.background,
        borderRadius: 20,
        textTransform: "none",
        color: '#fff',
        '&:hover': {
            background: theme.palette.background
        }
    },
    selectedButton: {
        background: '#fff',
        borderRadius: 20,
        textAlign: 'start',
        textTransform: "none",
        color: '#07AFD4',
        fontWeight: 700,
        '&:hover': {
            background: '#fff'
        }
    },
    textButton: {
        background: 'transparent',
        borderRadius: 20,
        textTransform: "none",
        color: '#fff',
        fontWeight: 700,
        '&:hover': {
            background: 'transparent'
        }
    },
    containedPrimaryButton: {
        background: theme.palette.background,
        borderRadius: 20,
        textTransform: "none",
        color: '#fff',
    },
    outlinedPrimaryButton: {
        // background: theme.palette.background,
        borderRadius: 20,
        borderColor: '#07AFD4',
        textTransform: "none",
    },
    uploadBtn: {
        borderColor: '#07AFD4',
        textTransform: "none",
    },
    textPrimaryButton: {
        color: '#07AFD4',
        textTransform: "none"
    }
}))

const TCButton = props => {
    const local_classes = useStyles();

    const { classes, btntype, children, ...rest } = props;

    let btnStyles = null;
    let btnVariant = null;

    const generateBtn = (customClasses, customVariant) => {
        return [clsx(customClasses, classes), customVariant];
    }

    switch(btntype) {
        case 'containedButton': [btnStyles, btnVariant] = generateBtn(local_classes.containedButton, 'contained');
            break;
        case 'selectedButton': [btnStyles, btnVariant] = generateBtn(local_classes.selectedButton, 'contained');
            break;
        case 'textButton': [btnStyles, btnVariant] = generateBtn(local_classes.textButton, 'text');
            break;
        case 'containedPrimaryButton': [btnStyles, btnVariant] = generateBtn(local_classes.containedPrimaryButton, 'contained');
            break;
        case 'outlinedPrimaryButton': [btnStyles, btnVariant] = generateBtn(local_classes.outlinedPrimaryButton, 'outlined'); 
            break;
        case 'textPrimary': [btnStyles, btnVariant] = generateBtn(local_classes.textPrimaryButton, 'text'); 
            break;
        case 'uploadBtn': [btnStyles, btnVariant] = generateBtn(local_classes.uploadBtn, 'outlined'); 
            break;
        default: [btnStyles, btnVariant] = generateBtn(local_classes.containedPrimaryButton, 'contained');
    }

    return <Button className={btnStyles} variant={btnVariant} {...rest}>{children}</Button>;

}

export default TCButton;