import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as actionTypes from '../../Store/actions/actionTypes';
import * as actionCreators from '../../Store/actions/actionCreators';
import firebase from '../../firebase';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    title: {
        background: theme.palette.background,
        padding: 16,
        textAlign: 'center',
        fontWeight: 700,
        color: '#fff'
    },
    input: {
        display: 'none',
    },
    progressDialog: {
        background: 'transparent',
        border: '0px',
        boxShadow: 'none',
        overflow: 'hidden'
    }
}))

export default function AddCourseDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const coursesState = useSelector(state => state.courses);
    const authState = useSelector(state => state.auth);

    const [image, setImage] = useState(null);
    const [link, setLink] = useState('');
    const [description, setDescription] = useState('');
    const [progress, setProgress] = useState({open: false, value: 0});

    const server_key = "AAAAZ18_OA4:APA91bEgIxN_LgB1cwCB2dGKg7P1Be9ilxoe_GbS9YNUOA26Yvpf7ZHrYb1EpK65uS7iwumuxIXXAdiRQvFU2U7KiU5tgqlGXybmZL4mCq4ldlNf6R4pD_vIybwmQr1J2T6aIJbHUHB6";

    const uploadFile = (payload, callback) => {
        const portal = authState.user.adminData.portal;
        const uploadTask = firebase.storage().ref(`${portal}/notificationImages/${payload.image.name}`).put(payload.image);
        uploadTask.on('state_changed',
            (snapshot) => {
                setProgress({open: true, value: snapshot.bytesTransferred/snapshot.totalBytes * 100});
            },
            (error) => {
                setProgress({open: false, value: 0});
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error: true,
                    errorMessage: 'Something went wrong. Please try again.'
                })
            },
            () => {
                firebase.storage().ref(portal + '/notificationImages').child(payload.image.name).getDownloadURL().then(url => {
                    payload.image = url;
                    callback(payload);
                    setProgress({open: false, value: 0});
                }).catch( err => {
                    setProgress({open: false, value: 0});
                    dispatch({
                        type: actionTypes.SET_ERROR,
                        error: true,
                        errorMessage: 'Something went wrong. Please try again.'
                    })
                })
            }
        )
    }

    const buildRequest = body => {
        return {
            url: `https://iid.googleapis.com/iid/v1/${props.token}/rel/topics/all`,
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `key=${server_key}` 
            },
            data: body
        }
    }

    const sendNotification = (payload) => {
        uploadFile(payload, (payload) => {
            setProgress({open: true, value: 0});
            const notificationBody = {
                "title": "TutorNex",
                "body": payload.description,
                "click_action": payload.link,
                "icon": payload.image
            }
            const body = {
                "notification": notificationBody,
                "to": "/topics/all"
            }
            const request = buildRequest(body);
            axios(request)
                .then(res => {
                    const portal = authState.user.adminData.portal;
                    const notificationRef = firebase.database().ref(portal + '/data/notifications');
                    if(coursesState.notificationHistory !== null){
                        notificationRef.set([notificationBody, ...coursesState.notificationHistory])
                    } else {
                        notificationRef.set([notificationBody])
                    }
                    dispatch(actionCreators.getAllCourses(portal));
                    dispatch({
                        type: actionTypes.SET_SUCCESS,
                        success: true,
                        successMessage: 'Notification sent successfully.'
                    })
                    handleClose();
                    setProgress({open: false, value: 0});
                })
                .catch(err => {
                    dispatch({
                        type: actionTypes.SET_ERROR,
                        error: true,
                        errorMessage: 'Something went wrong. Please try again.'
                    })
                    setProgress({open: false, value: 0});
                })
        })
    }

    const handleSendNotification = () => {
        let error = false;
        let errorMessage = "All fields are required to send notification.";

        if(image === null){
            error = true;
        }

        if(link === ''){
            error = true;
        }

        if(description === ''){
            error = true;
        }

        if(error){
            dispatch({
                type: actionTypes.SET_ERROR,
                error: true,
                errorMessage: errorMessage
            })
        } else {
            const payload = {
                image: image,
                link: link,
                description: description
            }
            sendNotification(payload);
        }
    }

    const handleClose = () => {
        setImage(null);
        setLink('');
        setDescription('');
        props.handleDialogClose();
    }

    return (
        <Fragment>
            <Dialog open={progress.open} PaperComponent={classes.progressDialog}>
                <div style={{padding: 16, overflow: 'hidden'}}>
                    <CircularProgress  value={progress.value} />
                </div>
            </Dialog>
            <Dialog open={props.open} onClose={handleClose}>
                <div style={{background: '#fff'}}>
                        <Typography variant='body1' className={classes.title}>Send Notification</Typography>
                        <DialogContent>

                        <input
                            accept="image/*"
                            className={classes.input}
                            onChange={(e) => setImage(e.target.files[0])}
                            id="contained-button-file"
                            type="file"
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant='outlined' color="primary" component="span" fullWidth style={{margin: '8px 0px'}}>
                                {
                                    image !== null
                                        ?   `Change Image`
                                        :   'Upload Image'
                                }
                            </Button>
                        </label>

                        <TextField
                            value={link}
                            margin="dense"
                            variant='outlined'
                            onChange={(e) => setLink(e.target.value)}
                            id="name"
                            label="Link"
                            fullWidth
                        />

                        <TextField
                            value={description}
                            margin="dense"
                            variant='outlined'
                            onChange={(e) => setDescription(e.target.value)}
                            id="name"
                            label="Description"
                            multiline
                            rowsMax={4}
                            fullWidth
                        />

                        <div style={{width: '100%', maxHeight: '200px', overflowX: 'auto', padding: 8, background: 'rgba(7, 175, 212, 0.05)', marginTop: 12, borderRadius: '4px'}}>
                            <Typography variant='body2' style={{fontWeight: 700}}>Notification History</Typography>
                            {
                                coursesState.notificationHistory !== null
                                    ?   coursesState.notificationHistory.map(n => {
                                        return (
                                            <div style={{border: '1px solid #07AFD4', margin: '4px 0px', padding: 4, background: 'rgba(7, 175, 212, 0.05)', borderRadius: '4px'}}>
                                                <Typography variant='body2'>{n.body}</Typography>
                                            </div>
                                        )
                                    })
                                    :   <Typography variant='body2' align='center'>No History Available.</Typography>
                            }
                        </div>
                        
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSendNotification} color="primary">
                            Send
                        </Button>
                        </DialogActions>
                    </div>
            </Dialog>
        </Fragment>
    );
}
