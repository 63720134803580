import React from 'react';
import { Dialog, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from '../../UIComponents/Button';
import { ReactComponent as DeleteImage } from '../../images/SVG_icons/delete1.svg';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 350,
        borderRadius: 20
    },
    topBar: {
        background: theme.palette.background,
        padding: 8,
        width: '100%',
        textAlign: 'center',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    topBarText: {
        color: '#fff',
        fontWeight: 700,
        padding: 8
    },
    btnContainer: {
        display: 'flex',
        background: '#fff',
        padding: 32,
        justifyContent: 'space-around',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20
    },
    dialogRoot: {
        borderRadius: 20,
    },
}))

const DeleteCourseDialog = props => {
    const classes = useStyles();

    return (
        <Dialog onClose={props.handleCloseDialog} open={props.open} PaperComponent={classes.dialogRoot}>
            <div className={classes.root}>
                <div className={classes.topBar}>
                    <Typography variant='body1' className={classes.topBarText}>Confirmation</Typography>
                </div>
                <div style={{padding: '16px, 0px', textAlign: 'center', background: '#fff'}}>
                    <DeleteImage style={{height: 150, padding: '20px'}}/>
                    <Typography variant='h6' color='secondary'>ARE YOU SURE ?</Typography>
                </div>
                <div className={classes.btnContainer}>
                    <Button btntype='outlinedPrimaryButton' onClick={props.handleCloseDialog} fullWidth style={{margin: '0px 22px'}}>Cancel</Button>
                    <Button onClick={() => props.handleCourseDelete(props.deleteCourse)} fullWidth style={{margin: '0px 22px'}}>Confirm</Button>
                </div>
            </div>
        </Dialog>
    );
}

export default DeleteCourseDialog;
