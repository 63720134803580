import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ChapterCard from '../../UIComponents/ChapterCard';
import TopicsAndQuizes from './TopicsAndQuizes';
import Reorder, { reorder } from 'react-reorder';
import firebase from '../../firebase';
import * as actionTypes from '../../Store/actions/actionTypes';
import * as actionCreators from '../../Store/actions/actionCreators';

const useStyles = makeStyles(theme => ({
    '@global': {
        '*::-webkit-scrollbar': {
          width: '0.15em',
          height: '0.15em'
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 12px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '1px solid slategrey'
        }
    },
    chapters: {
        overflowX: 'auto',
        maxHeight: '68.2vh',
        height: '68.2vh',
        borderRight: '1.5px solid rgba(0,0,0,.1)',
        paddingRight: 4 
    },
    topics: {
        overflowX: 'auto',
        maxHeight: '68.2vh',
        height: '68.2vh',
        paddingRight: 4 
    }
}))

const SelectedCourseBody = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const coursesState = useSelector(state => state.courses);
    const authState = useSelector(state => state.auth);

    const onReorderChapters = (event, previousIndex, nextIndex) => {
        const nonDeletedChapters = [...props.course.chapters.filter(chapter => chapter.deleted !== true)];
        const newNonDeletedChapters = reorder(nonDeletedChapters, previousIndex, nextIndex);
        const newChapters = [...newNonDeletedChapters, ...props.course.chapters.filter(chapter => chapter.deleted === true)]
        const newCourse = {
            ...props.course,
            chapters: newChapters
        }
        const courses = [];
        let refId = null;
        coursesState.courses.forEach(course => {
            if(course.id === newCourse.id){
                refId = newCourse.refId;
                courses.push({...newCourse, firebaseRefId: null})
            } else {
                courses.push({...course, firebaseRefId: null});
            }
        })
        const portal = authState.user.adminData.portal;
        const coursesRef = firebase.database().ref(portal + '/data/courses');
        coursesRef.set([...courses]);

        dispatch(actionCreators.updateSelectedCourseContent({
            ...newCourse,
            firebaseRefId: refId
        }, portal));

        dispatch({
            type: actionTypes.GET_ALL_COURSES,
            courses: [...courses]
        })
    }

    const getRestrictedCourses = (portal, courseId) => {
        const restrictedCourseRef = firebase.database().ref(`${portal}/paid_courses/courses/${courseId}`);
        return restrictedCourseRef.once('value', snapshot => {
            return snapshot;
        })
    }

    const onReorderTopics = async (event, previousIndex, nextIndex) => {
        const nonDeletedTopics = [...props.chapterSelected.topics.filter(topic => topic.deleted !== true)];
        const newNonDeletedTopics = reorder(nonDeletedTopics, previousIndex, nextIndex);
        const newTopics = [...newNonDeletedTopics, ...props.chapterSelected.topics.filter(topic => topic.deleted === true)];
        const newChapter = {...props.chapterSelected};
        newChapter.topics = [...newTopics];
        const courses = [];
        let newCourse = null;
        let refId = null;
        const portal = authState.user.adminData.portal;

        let restrictedCourse = null;
        if(coursesState.selectedCourse.restricted === true){
            restrictedCourse = await getRestrictedCourses(portal, coursesState.selectedCourse.id);
            restrictedCourse = restrictedCourse.val();
        }

        coursesState.courses.forEach(course => {
            if(course.id === coursesState.selectedCourse.id){
                refId = coursesState.selectedCourse.refId;
                const newChapters = [];
                let chaptersToMap = course.chapters ? course.chapters : [];
                if(course.restricted === true){
                    const resCourse = restrictedCourse;
                    // console.log('resCourse : ', resCourse);
                    // let resCourse = coursesState.restrictedCourses.filter(c => c.id === course.id)[0];
                    chaptersToMap = resCourse.chapters ? resCourse.chapters : [];
                }
                chaptersToMap.forEach(chapter => {
                    chapter.id === newChapter.id ? newChapters.push(newChapter) : newChapters.push(chapter);
                })
                newCourse = {
                    ...course,
                    chapters: newChapters
                }
                courses.push({...newCourse, firebaseRefId: null})
            } else {
                courses.push({...course, firebaseRefId: null});
            }
        })

        //console.log('Final : ', courses);
        const coursesRef = firebase.database().ref(portal + '/data/courses');
        coursesRef.set([...courses]);

        dispatch(actionCreators.updateSelectedCourseContent({
            ...newCourse,
            firebaseRefId: refId
        }, portal));

        dispatch({
            type: actionTypes.GET_ALL_COURSES,
            courses: [...courses]
        })
        props.handleChapterSelect(newChapter);
    }

    const body = chapters => {
        const nonDeletedChapters = chapters.filter(chapter => chapter.deleted !== true);
        const nonDeletedTopics = props.chapterSelected 
            ? (
                props.chapterSelected.topics
                ?   props.chapterSelected.topics.filter(topic => topic.deleted !== true)
                :   []
            ) : [];

        return (
            <Grid container style={{overflowX: 'hidden'}}>
                {
                    nonDeletedChapters && nonDeletedChapters.length > 0
                        ?   (
                                <Fragment>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3} className={classes.chapters} id='reorder-chapters' style={{overflowX: 'hidden'}}>
                                        <Reorder
                                            reorderId="my-chapters"
                                            reorderGroup="reorder-chapters"
                                            lock="horizontal"
                                            touchHoldTime={200}
                                            holdTime={100}
                                            onReorder={onReorderChapters}
                                            placeholder={
                                                <div style={{border: '1px dashed #aaa', background: '#cfd8dc', borderRadius: 4, padding: '8px 8px', height: 200}} />
                                            }
                                        >
                                        {
                                            
                                            nonDeletedChapters && nonDeletedChapters.map(chapter => (
                                                    <div style={{padding: '8px 0px'}} key={chapter.id} id='my-chapters'>
                                                        <ChapterCard 
                                                            chapter={chapter} 
                                                            chapterSelected={props.chapterSelected}
                                                            handleChapterEdit={props.handleChapterEdit} 
                                                            handleChapterDelete={props.handleChapterDelete}
                                                            handleChapterSelect={props.handleChapterSelect}    
                                                        />
                                                    </div>
                                                )
                                            )
                                        }
                                        </Reorder>
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} lg={9} xl={9} className={classes.topics} style={{overflowX: 'hidden'}}>
                                        {
                                            nonDeletedTopics && nonDeletedTopics.length > 0 
                                                ? <TopicsAndQuizes 
                                                    chapterSelected={props.chapterSelected}
                                                    handleEditTopic={props.handleEditTopic} 
                                                    handleDeleteTopic={props.handleDeleteTopic}
                                                    handleStartLiveClass={props.handleStartLiveClass}
                                                    onReorderTopics={onReorderTopics}
                                                    nonDeletedTopics={nonDeletedTopics}
                                                /> 
                                                : <Typography variant='body1' style={{padding: '0px 16px'}}>There are no topics or quizes added yet in this chapter. Start adding now.</Typography>
                                        }
                                    </Grid>
                                </Fragment>
                        ) : <Typography variant='body1'>There are no chapters added yet in this course. Start adding now.</Typography>
                }
            </Grid>
        )
    }

    return (
        <>
            {
                (props.course && props.course.chapters) ? body(props.course.chapters) : <Typography variant='body1'>There are no chapters added yet in this course. Start adding now.</Typography>
            }
        </>
    )
}

export default SelectedCourseBody;
