import React, { useEffect, useState, Fragment } from 'react';
import { Grid, Typography, Dialog, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MdAdd } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import * as actionTypes from '../../Store/actions/actionTypes';
import * as actionCreators from '../../Store/actions/actionCreators';
import firebase from '../../firebase';

import Reorder, { reorder } from 'react-reorder';

import Button from '../../UIComponents/Button';
import CourseCard from './CourseCard';
import AddOrEditCourseDialog from '../../Components/AddOrEditCourseDialog/AddOrEditCourseDialog';
import DeleteCourseDialog from './DeleteCourseDialog';

const useStyles = makeStyles(theme => ({
    addCourseBtnContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    progressDialog: {
        background: 'transparent',
        border: '0px',
        boxShadow: 'none'
    }
}))

const Courses = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const coursesState = useSelector(state => state.courses);
    const authState = useSelector(state => state.auth);

    const [coursesAvailable, setCoursesAvailable] = useState(null);
    const [addOrEditDialog, setAddOrEditDialog] = useState({ open: false, courseForEdit: null });
    const [deleteCourse, setDeleteCourse] = useState({ open: false, courseForDelete: null })
    const [progress, setProgress] = useState({ open: false, value: 0 });

    useEffect(() => {
        if (coursesState.courses) {
            if (coursesState.courses.length > 0) {
                setCoursesAvailable(true)
            } else {
                setCoursesAvailable(false)
            }
        }
    }, [coursesState])

    const handleCourseSelected = course => {
        const portal = authState.user.adminData.portal;
        dispatch(actionCreators.updateSelectedCourseContent(course, portal));
    }

    const getRestrictedCourses = (portal, courseId) => {
        const restrictedCourseRef = firebase.database().ref(`${portal}/paid_courses/courses/${courseId}`);
        return restrictedCourseRef.once('value', snapshot => {
            return snapshot;
        })
    }

    const handleFirebaseUpdate = async (payload, firebaseRefId, oldCourse) => {
        let newCourse = {...payload, chapters: oldCourse.chapters};
        const portal = authState.user.adminData.portal;
        const coursesRef = firebase.database().ref(portal + '/data/courses').child(firebaseRefId);
        coursesRef.update(payload);
        if(oldCourse.restricted){
            let restrictedCourses = await getRestrictedCourses(portal, payload.id);
            restrictedCourses = restrictedCourses.val();
            const restrictedChapters = restrictedCourses ? (restrictedCourses.chapters ? restrictedCourses.chapters : null) : null;
            newCourse = {...newCourse, chapters: restrictedChapters};
            const restrictedCourseRef = firebase.database().ref(`${portal}/paid_courses/courses/${newCourse.id}`);
            restrictedCourseRef.set(newCourse);
        }
        if(oldCourse.restricted !== payload.restricted){
            handleIsRestrictedEdit(newCourse);
        }
        dispatch(actionCreators.getAllCourses(portal));
        setAddOrEditDialog({ open: false, courseForEdit: null });
    }

    const handleCourseEdit = async (payload, firebaseRefId, newImage, oldCourse) => {
        const portal = authState.user.adminData.portal;
        payload.firebaseRefId = null;
        if (!newImage) {
            handleFirebaseUpdate(payload, firebaseRefId, oldCourse);
        } else {
            const imageName = `${payload.name.replace(/[^a-zA-Z0-9]/g, "")}-${Date.now()}`;
            const uploadTask = firebase.storage().ref(`${portal}/images/${imageName}`).put(payload.image);
            uploadTask.on('state_changed',
                (snapshot) => {
                    setProgress({ open: true, value: snapshot.bytesTransferred / snapshot.totalBytes * 100 });
                },
                (error) => {
                    setProgress({ open: false, value: 0 });
                    dispatch({
                        type: actionTypes.SET_ERROR,
                        error: true,
                        errorMessage: 'Something went wrong. Please try again.'
                    })
                },
                () => {
                    firebase.storage().ref(portal + '/images').child(imageName).getDownloadURL().then((url) => {
                        payload.image = imageName;
                        handleFirebaseUpdate(payload, firebaseRefId, oldCourse);
                        setProgress({ open: false, value: 0 });
                    }).catch(err => {
                        setProgress({ open: false, value: 0 });
                        dispatch({
                            type: actionTypes.SET_ERROR,
                            error: true,
                            errorMessage: 'Something went wrong. Please try again.'
                        })
                    })
                }
            )
        }
    }

    const handleCourseDelete = async course => {
        const newCourse = {
            ...course,
            deleted: true
        }
        const portal = authState.user.adminData.portal;
        if(newCourse.restricted === true){
            const restrictedCourse = await getRestrictedCourses(portal, newCourse.id);
            console.log('restrictedCourse : ', restrictedCourse.val());
            newCourse.chapters = restrictedCourse.val().chapters;
            const restrictedCourseRef = firebase.database().ref(`${portal}/paid_courses/courses/${newCourse.id}`);
            restrictedCourseRef.remove();
        }
        const coursesRef = firebase.database().ref(portal + '/data/courses').child(course.firebaseRefId);
        coursesRef.update(newCourse);
        setDeleteCourse({ open: false, courseForDelete: null })
        dispatch(actionCreators.getAllCourses(portal));
    }

    const handleAddCourse = payload => {
        const portal = authState.user.adminData.portal;
        const imageName = `${payload.name.replace(/[^a-zA-Z0-9]/g, "")}-${Date.now()}`;
        const uploadTask = firebase.storage().ref(`${portal}/images/${imageName}`).put(payload.image);
        uploadTask.on('state_changed',
            (snapshot) => {
                setProgress({ open: true, value: snapshot.bytesTransferred / snapshot.totalBytes * 100 });
            },
            (error) => {
                setProgress({ open: false, value: 0 });
                dispatch({
                    type: actionTypes.SET_ERROR,
                    error: true,
                    errorMessage: 'Something went wrong. Please try again.'
                })
            },
            () => {
                firebase.storage().ref(portal + '/images').child(imageName).getDownloadURL().then(url => {
                    payload.image = imageName;
                    let allCourses = [...coursesState.courses];
                    const course = { ...payload, id: allCourses.length + 1 }
                    const portal = authState.user.adminData.portal;
                    const coursesRef = firebase.database().ref(portal + '/data/courses');
                    coursesRef.set([...allCourses, course]);
                    if(payload.restricted){
                        const restrictedCourseRef = firebase.database().ref(`${portal}/paid_courses/courses/${course.id}`);
                        restrictedCourseRef.set(course);
                    }
                    setProgress({ open: false, value: 0 })
                    setAddOrEditDialog({ open: false, courseForEdit: null })
                    dispatch(actionCreators.getAllCourses(portal));
                }).catch(err => {
                    setProgress({ open: false, value: 0 });
                    dispatch({
                        type: actionTypes.SET_ERROR,
                        error: true,
                        errorMessage: 'Something went wrong. Please try again.'
                    })
                })
            }
        )
    }

    const handleIsLiveEdit = (course) => {
        const portal = authState.user.adminData.portal;
        const courses = [...coursesState.courses];
        const newCourses = [];
        courses.forEach(c => {
            if (c.id === course.id) {
                course.firebaseRefId = null;
                newCourses.push(course);
            } else {
                c.firebaseRefId = null;
                newCourses.push(c);
            }
        });
        const coursesRef = firebase.database().ref(portal + '/data/courses');
        coursesRef.set([...newCourses]);
        dispatch(actionCreators.getAllCourses(portal));
        dispatch({
            type: actionTypes.SET_SUCCESS,
            success: true,
            successMessage: 'Changes are updated successfully.'
        })
    }

    const handleIsRestrictedEdit = (course) => {
        const portal = authState.user.adminData.portal;
        const courseId = course.id;
        const coursesRef = firebase.database().ref(`${portal}/data/courses`);
        const paidCoursesRef = firebase.database().ref(`${portal}/paid_courses/courses/${courseId}`);

        if(course.restricted === true){
            course.firebaseRefId = null;
            paidCoursesRef.set(course);

            const courses = [...coursesState.courses];
            const newCourses = [];
            courses.forEach(c => {
                if (c.id === course.id) {
                    course.firebaseRefId = null;
                    course.chapters = null; 
                    newCourses.push(course);
                } else {
                    c.firebaseRefId = null;
                    newCourses.push(c);
                }
            });
            coursesRef.set([...newCourses]);
        } else {
            paidCoursesRef.once('value')
                .then(snapshot => {
                    const newCourse = snapshot.val();
                    const courses = [...coursesState.courses];
                    const newCourses = [];
                    courses.forEach(c => {
                        if (c.id === course.id) {
                            newCourse.restricted = false;
                            newCourses.push(newCourse);
                        } else {
                            c.firebaseRefId = null;
                            newCourses.push(c);
                        }
                    });
                    paidCoursesRef.remove();
                    coursesRef.set([...newCourses]);
                })
        }
        dispatch(actionCreators.getAllCourses(portal));
        dispatch({
            type: actionTypes.SET_SUCCESS,
            success: true,
            successMessage: 'Changes are updated successfully.'
        })
    }

    const onReorder = (event, previousIndex, nextIndex) => {
        const courses = [...coursesState.courses];
        courses.forEach(course => course.firebaseRefId = null);
        const newCourses = reorder(courses, previousIndex, nextIndex);
        const portal = authState.user.adminData.portal;
        const coursesRef = firebase.database().ref(portal + '/data/courses');
        coursesRef.set([...newCourses]);
        dispatch(actionCreators.getAllCourses(portal));
    }

    const getCoursesJSX = (coursesAvailable, courses) => {
        if (coursesAvailable === false) {
            return  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '65vh' }}>
                        <Typography style={{ fontWeight: 700 }}>No Courses Added Yet.</Typography>
                    </Grid>
        } else {
            return (
                <Grid container style={{ maxHeight: '82vh', overflowX: 'auto' }} spacing={1} id='reorder-course'>
                    <Reorder
                        reorderId="my-courses"
                        reorderGroup="reorder-course"
                        // lock="horizontal"
                        touchHoldTime={200}
                        holdTime={100}
                        onReorder={onReorder}
                        placeholder={
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} style={{ border: '1px dashed #aaa', background: '#cfd8dc', borderRadius: 4, padding: 4 }} />
                        }
                        style={{ display: 'flex', flexWrap: 'wrap', boxSizing: 'border-box', width: '100%' }}
                    >
                        {
                            courses.map(course => {
                                return (
                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={course.id} id='my-courses' style={{ padding: 4 }}>
                                        <CourseCard
                                            course={course}
                                            handleCourseSelected={handleCourseSelected}
                                            handleCourseEdit={(course) => setAddOrEditDialog({ open: true, courseForEdit: course })}
                                            handleCourseDelete={(course) => setDeleteCourse({ open: true, courseForDelete: course })}
                                            handleIsRestrictedEdit={handleIsRestrictedEdit}
                                            handleIsLiveEdit={handleIsLiveEdit}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </Reorder>
                </Grid>
            )
        }
    }

    return (
        <Fragment>
            <AddOrEditCourseDialog
                open={addOrEditDialog.open}
                course={addOrEditDialog.courseForEdit}
                handleAddCourse={handleAddCourse}
                handleCourseEdit={handleCourseEdit}
                handleDialogClose={() => setAddOrEditDialog({ open: false, courseForEdit: null })}
            />
            <DeleteCourseDialog
                open={deleteCourse.open}
                deleteCourse={deleteCourse.courseForDelete}
                handleCourseDelete={handleCourseDelete}
                handleCloseDialog={() => setDeleteCourse({ open: false, courseForDelete: null })}
            />
            <Dialog open={progress.open} PaperComponent={classes.progressDialog}>
                <div style={{padding: 16, overflow: 'hidden'}}>
                    <CircularProgress value={progress.value} />
                </div>
            </Dialog>
            <div style={{ width: '100%', padding: 16 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <img alt='TutorNex' src='/assets/logo.png' height='60px' />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} className={classes.addCourseBtnContainer}>
                        <Button btntype='containedButton' startIcon={<MdAdd />} onClick={() => setAddOrEditDialog({ open: true, courseForEdit: null })}>Add Course</Button>
                    </Grid>
                    {coursesAvailable !== null ? getCoursesJSX(coursesAvailable, coursesState.courses) : null}
                </Grid>
            </div>
        </Fragment>
    )
}

export default Courses;
