import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from '../../UIComponents/Button';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 350,
        borderRadius: 20,
        background: '#fff'
    },
    topBar: {
        background: theme.palette.background,
        width: '100%',
        textAlign: 'center',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    topBarText: {
        color: '#fff',
        fontWeight: 700,
        padding: 12
    },
    bodyText: {
        margin: '32px', 
        textAlign: 'center', 
        background: '#fff'
    },
    btnContainer: {
        display: 'flex',
        background: '#fff',
        padding: '0px 16px 16px 16px',
        justifyContent: 'space-around',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20
    }
}))

const UpdateIsLiveDialogContent = props => {
    const classes = useStyles();

    return (
        <Fragment>
            {
                props.course !== null
                    ? <div className={classes.root}>
                        <div className={classes.topBar}>
                            <Typography variant='body1' className={classes.topBarText}>Confirmation</Typography>
                        </div>
                        <div className={classes.bodyText}>
                            <Typography variant="body1" style={{}}>
                                {
                                    props.course.isLive === true
                                        ? "Are you sure you don't want this course to be live?"
                                        : "Are you sure you want to take this course live?"
                                }
                            </Typography>
                        </div>
                        <div className={classes.btnContainer}>
                            <Button btntype='outlinedPrimaryButton' onClick={props.handleCloseDialog}>Cancel</Button>
                            <Button onClick={() => props.handleCourseEdit(props.course)}>Confirm</Button>
                        </div>
                    </div>
                    : null
            }
        </Fragment>
    );
}

export default UpdateIsLiveDialogContent;