import React from 'react';
import { Grid, Typography, Button, withWidth } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// import ReactPlayer from 'react-player/youtube';
import history from '../../Utils/History';


const useStyles = makeStyles(theme => ({
    root: {
        // backgroundImage: `url(/assets/Dashboard_Demo-video.png)`,
        // backgroundRepeat: 'no-repeat',
    },
    gridItemLeft: {
        padding: 36,
    },
    gridItemRight: {
        padding: 36,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            height: '70vh'
        }
    },
    bold: {
        fontWeight: 700,
    },
    ButtonContainer: {
        width: '100%',
        textAlign: 'center',
        padding: '12px'
    },
    btn: {
        fontWeight: 700
    }
}))

const LandingPage = props => {
    const classes = useStyles();

    const handleSkipClicked = () => {
        history.replace({
            pathname: '/login'
        });
    }

    return (
        <Grid container className={classes.root}>
            {
                props.width === 'xs'
                    ?   null
                    :   props.width === 'sm'
                        ?   null
                        :   <img src="/assets/Dashboard_Demo-video.png" alt="" width="100%" height="100vh" style={{objectFit: 'cover', position: 'absolute', width: '100%', height: '100vh', zIndex: -1}} />
            }
            {/* <img src="/assets/Dashboard_Demo-video.png" alt="" width="100%" height="100vh" style={{objectFit: 'cover', position: 'absolute', width: '100%', height: '100vh'}} /> */}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.gridItemLeft}>
                <Typography variant='h6' className={classes.bold} color='primary' style={{color: '#07AFD4'}}>
                    Let's
                    <br />
                    Get Started
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.gridItemRight}>
                <div>
                    <Typography variant='h4' color='primary' align='center' style={{color: '#07AFD4'}}>Demo Video</Typography>
                    <br />
                    <Typography variant='h6' color='primary' align='center' style={{color: '#07AFD4'}} className={classes.bold}>Would you like to play ?</Typography>
                    <br />
                    {/* eslint-disable-next-line */}
                    <iframe width={props.width === 'xs' ? "100%" : props.width === 'xl' ? "550px" : (props.width === 'lg' ? "450px" : "350px" )}
                            height={props.width === 'xs' ? "100%" : props.width === 'xl' ? '350px' : '250px'}
                            src="https://www.youtube.com/embed/h-_Sdkiz7Lc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    <br />
                    <div className={classes.ButtonContainer}>
                        <Button onClick={handleSkipClicked} className={classes.btn}>Skip</Button>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}

export default withWidth()(LandingPage);
