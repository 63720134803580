import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import { IoIosSend } from 'react-icons/io';
import firebase from '../../firebase';
import * as actionTypes from '../../Store/actions/actionTypes';

import SendNotificationDialog from '../../Components/SendNotificationDialog/SendNotificationDialog';

const useStyles = makeStyles(theme => ({
  appBar: {
    background: '#fff'
  }
}))

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 3,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const ElevateAppBar = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [sendNotification, setSendNotification] = useState(false);
  const [token, setToken] = useState('');

  const handleSendNotification = async () => {
    try {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      setToken(token)
      setSendNotification(true)
    } catch (error) {
      dispatch({
        type: actionTypes.SET_ERROR,
        error: true,
        errorMessage: 'Permission denied to send notification.'
      })
    }
  }

  return (
    <React.Fragment>
      <SendNotificationDialog 
        open={sendNotification}
        token={token}
        handleDialogClose={() => setSendNotification(false)}
      />
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <div style={{flexGrow: 1}}>
              <img alt='TutorNex' src='/assets/logo.png' height='60px'/>
            </div>
            <IconButton onClick={handleSendNotification} variant='outlined'><IoIosSend color='#07AFD4' /></IconButton>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </React.Fragment>
  );
}

export default ElevateAppBar;
