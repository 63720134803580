import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as actionTypes from '../../Store/actions/actionTypes';
import Button from '../../UIComponents/Button';
import validator from 'validator';
import firebase from '../../firebase';
import axios from 'axios';
import { getIdToken } from '../../helpers/getIdToken';
import PhoneInput from '../../UIComponents/PhoneInput/PhoneInput';
import * as Constants from '../../helpers/constants';

const useStyles = makeStyles(theme => ({
    title: {
        background: theme.palette.background,
        padding: 16,
        textAlign: 'center',
        fontWeight: 700,
        color: '#fff',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    input: {
        display: 'none',
    },
    dialogRoot: {
        borderRadius: 20,
    },
    btnContainer: {
        display: 'flex',
        background: '#fff',
        width: '100%',
        padding: '0px 16px 16px 16px',
        justifyContent: 'space-around',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20
    }
}))

const AddStudentToRestrictedCourse = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const authState = useSelector(state => state.auth);
    const courseState = useSelector(state => state.courses);

    const [phone, setPhone] = useState('');
    const [accessUntil, setAccessUntil] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [accessUntilError, setAccessUntilError] = useState(false);

     // eslint-disable-next-line
     const [country, setCountry] = useState('in');

    const handleSubmit = () => {
        let error = false;

        if(!validator.isMobilePhone(`+${phone}`)){
            error = true;
            setPhoneError(true);
        }

        if(validator.isEmpty(accessUntil)){
            error = true;
            setAccessUntilError(true);
        }

        if(!error){
            props.setProgress({open: true, value: 0});
            getIdToken((res) => {
                if(res.success){
                    const idToken = res.idToken;
                    const phoneNumber = phone[0] === '+' ? phone.slice(1) : phone;
                    const baseURL = Constants.BASE_URL;
                    const url = `${baseURL}getUID?phone=${phoneNumber}`;

                    axios.get(url, { headers: {authorization: idToken} })
                        .then((res) => {
                            if(res.data && res.data.status && res.data.status === 'success'){
                                const uid = res.data.uid;
                                const courseId = courseState.selectedCourse.id;
                                const portal = authState.user.adminData.portal;
                                const db = `${portal}/paid_accesses/${uid}/${courseId}`;
                                const paidAccessesRef = firebase.database().ref(db);


                                paidAccessesRef.set(accessUntil).then(() => {
                                    dispatch({
                                        type: actionTypes.SET_SUCCESS,
                                        success: true,
                                        successMessage: 'Student added successfully to the course.'
                                    });
                                    props.setProgress({open: false, value: 0});
                                    props.setFetchStudents(true);
                                    handleClose();
                                }).catch((err) => {
                                    console.log(err);
                                    props.setProgress({open: false, value: 0});
                                    dispatch({
                                        type: actionTypes.SET_ERROR,
                                        error: true,
                                        errorMessage: 'Unable to connect.'
                                    })
                                });
                            } else {
                                props.setProgress({open: false, value: 0});
                                dispatch({
                                    type: actionTypes.SET_ERROR,
                                    error: true,
                                    errorMessage: 'Student is not on the platform yet.'
                                })
                            }
                        })
                        .catch((err) => {
                            props.setProgress({open: false, value: 0});
                            dispatch({
                                type: actionTypes.SET_ERROR,
                                error: true,
                                errorMessage: 'Something went wrong while getting student uid.'
                            })
                        })
                } else {
                    props.setProgress({open: false, value: 0});
                    dispatch({
                        type: actionTypes.SET_ERROR,
                        error: true,
                        errorMessage: res.errorMessage
                    })
                }
            })
        }
    }

    const clearForm = () => {
        setPhone('');
        setAccessUntil('');
        setPhoneError(false);
        setAccessUntilError(false);
    }

    const handleClose = () => {
        clearForm();
        props.onClose();
    }

    const formatDate = (date, addBy) => {
        let d = new Date(date);
        d.setDate(d.getDate() + addBy);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    return (
        <Dialog open={props.open} PaperComponent={classes.dialogRoot}>
            <div style={{background: '#fff', borderRadius: 20}}>
                    <Typography variant='body1' className={classes.title}>Add Student</Typography>
                    <DialogContent style={{padding: 32, maxWidth: 500}}>
                        <PhoneInput 
                                country={country}
                                isValid={!phoneError}
                                enableSearch={true}
                                value={phone}
                                onChange={
                                    (value) => {
                                        setPhone(value);
                                        phoneError && setPhoneError(false);
                                    }
                                }
                                helperText={phoneError ? 'Please enter a valid phone number.' : ''}
                        />
                        <div style={{width: '100%', height: 16}} />
                        <TextField
                            id="date"
                            margin="dense"
                            variant='outlined'
                            label="Access Untill"
                            type="date"
                            value={accessUntil}
                            onChange={(e) => {
                                setAccessUntil(e.target.value);
                                accessUntilError && setAccessUntilError(false);
                            }}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                min: formatDate(Date.now(), 1),
                            }}
                            error={accessUntilError}
                            helperText={accessUntilError ? 'Please enter a valid date.' : ''}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <div className={classes.btnContainer}>
                            <Button btntype='outlinedPrimaryButton' onClick={handleClose} fullWidth style={{margin: '0px 16px'}}>
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit} fullWidth style={{margin: '0px 16px'}}>
                                Submit
                            </Button>
                        </div>
                    </DialogActions>
                </div>
        </Dialog>
    )
}

export default AddStudentToRestrictedCourse;
