import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';

/*const firebaseConfig = {
    apiKey: "AIzaSyBfgpxi1_FRv1rnifFEOG4RCL5Jp6LPg3Q",
    authDomain: "tutornex-stage.firebaseapp.com",
    databaseURL: "https://tutornex-stage.firebaseio.com",
    projectId: "tutornex-stage",
    storageBucket: "tutornex-stage.appspot.com",
    messagingSenderId: "443979610126",
    appId: "1:443979610126:web:5ee8514e0649afe6e7645f",
    measurementId: "G-3QP7MENDMD"
};*/


// C4Career
/*const firebaseConfig = {
    apiKey: "AIzaSyDP1PDvyrnY8RTjQx6xYsx1z4jJ7LNoM_4",
    authDomain: "c4career.firebaseapp.com",
    databaseURL: "https://c4career.firebaseio.com",
    projectId: "c4career",
    storageBucket: "c4career.appspot.com",
    messagingSenderId: "290869798222",
    appId: "1:290869798222:web:d39a885ef6de3dcf696a1f",
    measurementId: "G-TV7YY993LQ"
};*/

// TutorNex
const firebaseConfig = {
    apiKey: "AIzaSyCNzMm5-TB0idfSPxxLfgpyZpVWS8A4TNw",
    authDomain: "tutornex.firebaseapp.com",
    databaseURL: "https://tutornex.firebaseio.com",
    projectId: "tutornex",
    storageBucket: "tutornex.appspot.com",
    messagingSenderId: "1071626408817",
    appId: "1:1071626408817:web:513d2cf116da1635c9dcd0",
    measurementId: "G-Z5S66477JV"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;