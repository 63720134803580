import React, { useState, useEffect } from 'react';
import {
    Button as MuiButton,
    TextField,
    Dialog,
    Checkbox,
    FormControlLabel,
    DialogActions,
    DialogContent,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as actionTypes from '../../Store/actions/actionTypes';
import { useDispatch } from 'react-redux';
import Button from '../../UIComponents/Button';
import { BsUpload } from 'react-icons/bs';

const useStyles = makeStyles(theme => ({
    title: {
        background: theme.palette.background,
        padding: 16,
        textAlign: 'center',
        fontWeight: 700,
        color: '#fff',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    input: {
        display: 'none',
    },
    inputContainer: {
        display: 'inline-block',
        padding: 4,
        minWidth: '50%'
    },
    formControlSelect: {
        width: '100%'
    },
    dialogRoot: {
        borderRadius: 20,
    },
    btnContainer: {
        display: 'flex',
        background: '#fff',
        width: '100%',
        padding: '0px 16px 16px 16px',
        justifyContent: 'space-around',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20
    }
}))

export default function AddOrEditTopicsDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [document, setDocument] = useState(null);
    const [description, setDescription] = useState('');
    const [youtubeLink, setYoutubeLink] = useState('');
    const [live, setLive] = useState(false);
    const [joinLink, setJoinLink] = useState('');
    const [instructions, setInstructions] = useState('');

    useEffect(() => {
        if(props.topic) {
            setTitle(props.topic.name);
            props.topic.description &&  setDescription(props.topic.description);
            props.topic.video && setYoutubeLink(props.topic.video);
            props.topic.additional_notes && setDocument(props.topic.additional_notes);
            props.topic.image && setImage(props.topic.image);
            props.topic.join_link && setJoinLink(props.topic.join_link);
            props.topic.instructions && setInstructions(props.topic.instructions);
            props.topic.type && setLive(props.topic.type === 'live' ? true : false);
        } else {
            setTitle('');
            setImage(null);
            setDocument(null);
            setDescription('');
            setYoutubeLink('');
            setLive(false);
            setInstructions(null);
            setJoinLink(null);
        }
    }, [props.topic])

    const handleAddOrEditTopic = () => {
        const payload = {
            name: title,
            image: image,
            additional_notes: document,
            description: description,
            video: youtubeLink,
            type: live ? 'live' : 'video',
            instructions: instructions,
            join_link: joinLink
        }
        
        let error = false;
        let errorMessage = 'Invalid Inputs! Please fill all the mandatory fields.'
        if(payload.name === ''){
            error = true;
        }
        if(payload.description === ''){
            error = true
        }
        if(payload.video === '' && payload.image === null){
            error = true;
            errorMessage = 'Either youtube link or image is required.';
        }
        if(payload.type === 'live') {
            if(!(instructions || joinLink)) {
                error = true;
                errorMessage = 'Provide both instructions and session id for live topic';
            }
        }

        if(error){
            dispatch({
                type: actionTypes.SET_ERROR,
                error: true,
                errorMessage: errorMessage
            })
        } else {
            if(props.topic){
                props.handleAddnewTopic(payload);
            } else {
                props.handleAddnewTopic(payload);
            }
        }
    }

    const topic = (
        <div style={{background: '#fff', borderRadius: 20, maxWidth: 500}}>
            <Typography variant='body1' className={classes.title}>{ props.topic ? 'Edit Topic' : 'Add Topic' }</Typography>
            <DialogContent>
            <TextField
                autoFocus
                value={title}
                margin="dense"
                variant='outlined'
                onChange={(e) => setTitle(e.target.value)}
                id="name"
                label="Topic Name"
                fullWidth
            />
            <TextField
                value={description}
                margin="dense"
                variant='outlined'
                onChange={(e) => setDescription(e.target.value)}
                id="description"
                label="Description"
                multiline
                rowsMax={4}
                fullWidth
            />
            <TextField
                value={youtubeLink}
                margin="dense"
                variant='outlined'
                onChange={(e) => setYoutubeLink(e.target.value)}
                id="youtube"
                label="Youtube Link"
                fullWidth
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={live}
                        onChange={(e) => setLive(e.target.checked)}
                        color="primary"
                    />
                }
                label="Is a live topic"
            />
            {
                live ? <TextField
                            autoFocus
                            value={instructions}
                            margin="dense"
                            variant='outlined'
                            autoComplete="off"
                            onChange={(e) => setInstructions(e.target.value)}
                            multiline
                            rowsMax={4}
                            label="Join Instructions"
                            fullWidth
                        />
                    :   null
            }
            {
                live ? <TextField
                            autoFocus
                            value={joinLink}
                            margin="dense"
                            variant='outlined'
                            onChange={(e) => setJoinLink(e.target.value)}
                            label="Live Session ID"
                            fullWidth
                        />
                    :   null
            }
            <input
                accept="image/*"
                className={classes.input}
                onChange={(e) => setImage(e.target.files[0])}
                id="contained-button-file"
                type="file"
            />
            <label htmlFor="contained-button-file">
                <MuiButton variant='outlined' color="primary" component="span" fullWidth style={{margin: '8px 0px'}}>
                    {
                        image !== null
                            ?   `Change Image`
                            :   `${props.topic !== undefined && props.topic !== null && props.topic.image ? 'Change Image' : 'Upload Image'}`
                    }
                    <BsUpload size='12px' color='#07AFD4' style={{marginLeft: 8}} />
                </MuiButton>
            </label>
            <input
                accept="application/pdf"
                className={classes.input}
                onChange={(e) => setDocument(e.target.files[0])}
                id="contained-button-pdffile"
                type="file"
            />
            <label htmlFor="contained-button-pdffile">
                <MuiButton variant='outlined' color="primary" component="span" fullWidth style={{margin: '8px 0px'}}>
                    {
                        document !== null
                            ?   `Change Document`
                            :   `${props.topic !== undefined && props.topic !== null && props.topic.document ? 'Change Document' : 'Upload Document'}`
                    }
                    <BsUpload size='12px' color='#07AFD4' style={{marginLeft: 8}} />
                </MuiButton>
            </label>
            </DialogContent>
            <DialogActions>
                <div className={classes.btnContainer}>
                    <Button btntype='outlinedPrimaryButton' onClick={props.handleDialogClose} fullWidth style={{margin: '0px 6px'}}>
                        Cancel
                    </Button>
                    <Button onClick={handleAddOrEditTopic} fullWidth style={{margin: '0px 6px'}}>
                        {
                            props.topic ? 'Update' : 'Create'
                        }
                    </Button>
                </div>
            </DialogActions>
        </div>
    )

    return (
        <Dialog open={props.open} onClose={props.handleDialogClose} PaperComponent={classes.dialogRoot}>
            {topic}
        </Dialog>
    );
}
