import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        bottom: 0,
        right: 8,
        display: 'flex',
        background: '#fff',
        width: '100%',
        justifyContent: 'flex-end',
        padding: '2px 0px',
        opacity: '0.8'
    }
}))

const Footer = props => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Link href='https://tutornex.com/#contact'>Contact Us</Link>
            <Typography style={{padding: '0px 8px'}}>｜</Typography>
            <Link href='#'>Terms and Conditions</Link>
            <Typography style={{padding: '0px 8px'}}>｜</Typography>
            <Link href='https://tutornex.com/privacy-policy.html'>Privacy Policy</Link>
        </div>
    );
}

export default Footer;
